import React, { useState, useEffect } from 'react';
// import { TextField, MenuItem, Button } from '@mui/material';
import { TextField, MenuItem, Button, Autocomplete, Grid, Box, Paper, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { clientLocation } from '../../slotData/slotData';

const SlotsInfoPage = () => {
    const [centers] = useState([
        'Kotla', 'Pitampura', 'Chandigarh', 'Ludhiana', 'Hyderabad', 'Indore', 'Noida',
        'Bangalore', 'Gurgaon', 'Ahmedabad', 'Mumbai', 'Pune', 'Surat'
    ]);
    const [selectedCenter, setSelectedCenter] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [slots, setSlots] = useState([]);  // To store fetched slot data
    const [loading, setLoading] = useState(false);  // Loading state

    // Fetch products from the API
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SCHEDULER_API_URL}/api/v1/misc/get-products`)
            .then(response => {
                setProducts(response.data.productList);
            })
            .catch(error => {
                console.error('Error fetching products:', error);
            });
    }, []);

    // One-time refresh logic using session storage
    useEffect(() => {
        const hasRefreshed = sessionStorage.getItem('hasRefreshed');

        if (!hasRefreshed) {
            sessionStorage.setItem('hasRefreshed', 'true');
            window.location.reload();  // Refresh the page one time
        }
    }, []);

    // Get current date and generate the next 7 dates
    const generateNext7Days = () => {
        const dates = [];
        const today = new Date();
        for (let i = 0; i < 90; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);

            // Adjust for timezone differences by using the local time
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');  // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');

            dates.push({
                display: date.toLocaleDateString('en-GB'), // e.g., 24/09/2023
                value: `${year}-${month}-${day}`,           // e.g., 2023-09-24 in local timezone
            });
        }
        return dates;
    };

    console.log()

    const dates = generateNext7Days();

    // Handle fetch availability
    const handleFetchAvailability = () => {
        if (!selectedCenter || !selectedProduct || !selectedDate) {
            return;
        }

        setLoading(true);  // Set loading to true before fetching data

        const centerInfo = clientLocation[selectedCenter.toLowerCase()];
        const product = products.find(p => p.id === selectedProduct);

        const requestData = {
            slotDate: selectedDate,
            city: centerInfo.city,
            productId: product.id,
            clientLat: centerInfo.lat,
            clientLong: centerInfo.long,
        };

        axios.post(`${process.env.REACT_APP_SCHEDULER_API_URL}/api/v1/slot/get-client-slot-for-dashboard`, requestData)
            .then(response => {
                const sortedSlots = response.data.slots.sort((a, b) =>
                    a.clientSlotStartTime.localeCompare(b.clientSlotStartTime)
                );
                const oneHourSlots = groupSlotsIntoOneHour(sortedSlots); // Group slots into 1-hour intervals
                setSlots(oneHourSlots);
            })
            .catch(error => {
                setSlots([]);
                console.error('Error fetching slots:', error);
            })
            .finally(() => {
                setLoading(false);  // Turn off loading after the data is fetched
            });
    };

    // Group slots into 1-hour intervals
    const groupSlotsIntoOneHour = (slots) => {
        let oneHourSlots = [];

        for (let i = 0; i < slots.length; i++) {
            const startHour = slots[i]?.clientSlotStartTime.split(':')[0];
            const endHour = `${parseInt(startHour, 10) + 1}:00`; // Add 1 hour to the start time
            const slotExists = oneHourSlots.some(slot => slot.clientSlotStartTime.split(':')[0] === startHour);

            if (!slotExists) {
                oneHourSlots.push({
                    clientSlotStartTime: slots[i]?.clientSlotStartTime,
                    clientSlotEndTime: endHour,
                });
            }
        }

        return oneHourSlots;
    };

    return (
        // <div style={{ padding: '20px' }}>
        //     <h1>Scheduler Slots Info</h1>

        //     {/* Centers Dropdown */}
        //     <TextField
        //         select
        //         label="Centers"
        //         value={selectedCenter}
        //         onChange={e => setSelectedCenter(e.target.value)}
        //         fullWidth
        //         margin="normal"
        //     >
        //         {centers.map(center => (
        //             <MenuItem key={center} value={center}>
        //                 {center.charAt(0).toUpperCase() + center.slice(1).toLowerCase()}
        //             </MenuItem>
        //         ))}
        //     </TextField>

        //     {/* Products Dropdown with Search */}
        //     <TextField
        //         select
        //         label="Products"
        //         value={selectedProduct}
        //         onChange={e => setSelectedProduct(e.target.value)}
        //         fullWidth
        //         margin="normal"
        //         SelectProps={{
        //             native: false,
        //             renderValue: (value) => {
        //                 const product = products.find(p => p.id === value);
        //                 return product ? `${product.name} - ${product.gender}` : '';
        //             },
        //         }}
        //     >
        //         {products.map(product => (
        //             <MenuItem key={product.id} value={product.id}>
        //                 {product.name} - {product.gender}
        //             </MenuItem>
        //         ))}
        //     </TextField>

        //     {/* Date Dropdown */}
        //     <TextField
        //         select
        //         label="Select Date"
        //         value={selectedDate}
        //         onChange={e => setSelectedDate(e.target.value)}
        //         fullWidth
        //         margin="normal"
        //     >
        //         {dates.map(date => (
        //             <MenuItem key={date.value} value={date.value}>
        //                 {date.display}
        //             </MenuItem>
        //         ))}
        //     </TextField>

        //     {/* Fetch Availability Button */}
        //     <Button
        //         variant="contained"
        //         color="primary"
        //         fullWidth
        //         onClick={handleFetchAvailability}
        //         disabled={!selectedCenter || !selectedProduct || !selectedDate || loading}
        //         style={{ marginTop: '20px' }}
        //     >
        //         {loading ? 'Fetching Slots...' : 'Fetch Availability'}
        //     </Button>

        //     {/* Number of Slots */}
        //     {slots.length > 0 && (
        //         <div style={{ marginTop: '20px' }}>
        //             <h2>Number of Slots - {slots.length}</h2>
        //         </div>
        //     )}

        //     {/* Display Available Slots */}
        //     {slots.length > 0 && (
        //         <div style={{ marginTop: '20px' }}>
        //             <h2>Available Slots:</h2>
        //             <ul style={{ padding: '0', listStyleType: 'none' }}>
        //                 {slots.map((slot, index) => (
        //                     <li
        //                         key={index}
        //                         style={{
        //                             border: '1px solid #ccc',
        //                             borderRadius: '8px',
        //                             padding: '10px',
        //                             marginBottom: '10px',
        //                             textAlign: 'center'
        //                         }}
        //                     >
        //                         {slot.clientSlotStartTime} - {slot.clientSlotEndTime}
        //                     </li>
        //                 ))}
        //             </ul>
        //         </div>
        //     )}

        //     {slots.length === 0 && !loading && (
        //         <div style={{ marginTop: '20px' }}>
        //             <h2>No Slots Available</h2>
        //         </div>
        //     )}
        // </div>
        // <Box sx={{ padding: '20px', maxWidth: '900px', margin: '0 auto' }}>
        //     <Typography variant="h4" align="center" gutterBottom>
        //         Scheduler Slots Info
        //     </Typography>

        //     <Grid container spacing={2}> 
        //         <Grid item xs={12}>
        //             <TextField
        //                 select
        //                 label="Select Center"
        //                 value={selectedCenter}
        //                 onChange={e => setSelectedCenter(e.target.value)}
        //                 fullWidth
        //                 margin="dense" 
        //             >
        //                 {centers.map(center => (
        //                     <MenuItem key={center} value={center}>
        //                         {center.charAt(0).toUpperCase() + center.slice(1).toLowerCase()}
        //                     </MenuItem>
        //                 ))}
        //             </TextField>
        //         </Grid>
        //         <Grid item xs={12}>
        //             <Autocomplete
        //                 options={products}
        //                 getOptionLabel={(option) => `${option.name} - ${option.gender}`}
        //                 value={products.find(p => p.id === selectedProduct) || null}
        //                 onChange={(e, value) => setSelectedProduct(value ? value.id : '')}
        //                 renderInput={(params) => (
        //                     <TextField
        //                         {...params}
        //                         label="Select Product"
        //                         variant="outlined"
        //                         fullWidth
        //                         margin="dense" 
        //                     />
        //                 )}
        //             />
        //         </Grid>
        //         <Grid item xs={12}>
        //             <TextField
        //                 select
        //                 label="Select Date"
        //                 value={selectedDate}
        //                 onChange={e => setSelectedDate(e.target.value)}
        //                 fullWidth
        //                 margin="dense" 
        //             >
        //                 {dates.map(date => (
        //                     <MenuItem key={date.value} value={date.value}>
        //                         {date.display}
        //                     </MenuItem>
        //                 ))}
        //             </TextField>
        //         </Grid>

        //         <Grid item xs={12}>
        //             <Button
        //                 variant="contained"
        //                 color="primary"
        //                 fullWidth
        //                 onClick={handleFetchAvailability}
        //                 disabled={!selectedCenter || !selectedProduct || !selectedDate || loading}
        //                 sx={{ marginTop: '10px', padding: '12px 20px' }}
        //             >
        //                 {loading ? <CircularProgress size={24} color="inherit" /> : 'Fetch Availability'}
        //             </Button>
        //         </Grid>
        //     </Grid>


        //     {slots.length > 0 && (
        //         <Box sx={{ marginTop: '30px' }}>
        //             <Typography variant="h5" gutterBottom>
        //                 Available Slots ({slots.length}):
        //             </Typography>
        //             <Grid container spacing={2}>
        //                 {slots.map((slot, index) => (
        //                     <Grid item xs={12} md={3} key={index}>  {/* 4 slots per row on medium and above screens */}
        //                         <Paper elevation={2} sx={{ padding: '10px', textAlign: 'center' }}>
        //                             {slot.clientSlotStartTime} - {slot.clientSlotEndTime}
        //                         </Paper>
        //                     </Grid>
        //                 ))}
        //             </Grid>
        //         </Box>
        //     )}

        //     {slots.length === 0 && !loading && (
        //         <Box sx={{ marginTop: '30px', textAlign: 'center' }}>
        //             <Typography variant="h6" color="textSecondary">
        //                 No Slots Available
        //             </Typography>
        //         </Box>
        //     )}
        // </Box>
        <Box sx={{ padding: '20px', maxWidth: '900px', margin: '0 auto' }}>
            <Typography variant="h4" align="center" gutterBottom>
                Scheduler Slots Info
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Select Center"
                        value={selectedCenter}
                        onChange={e => setSelectedCenter(e.target.value)}
                        fullWidth
                        margin="dense"
                    >
                        {centers.map(center => (
                            <MenuItem key={center} value={center}>
                                {center.charAt(0).toUpperCase() + center.slice(1).toLowerCase()}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        options={products}
                        getOptionLabel={(option) => `${option.name} - ${option.gender}`}
                        value={products.find(p => p.id === selectedProduct) || null}
                        onChange={(e, value) => setSelectedProduct(value ? value.id : '')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Product"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        select
                        label="Select Date"
                        value={selectedDate}
                        onChange={e => setSelectedDate(e.target.value)}
                        fullWidth
                        margin="dense"
                    >
                        {dates.map(date => (
                            <MenuItem key={date.value} value={date.value}>
                                {date.display}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleFetchAvailability}
                        disabled={!selectedCenter || !selectedProduct || !selectedDate || loading}
                        sx={{ marginTop: '10px', padding: '12px 20px' }}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Fetch Availability'}
                    </Button>
                </Grid>
            </Grid>

            {slots.length > 0 && (
                <Box sx={{ marginTop: '30px' }}>
                    <Typography variant="h5" gutterBottom sx={{ marginBottom: '30px' }}>
                        Available Slots ({slots.length}):
                    </Typography>
                    <Grid container spacing={2}>
                        {slots.map((slot, index) => (
                            <Grid item xs={12} md={3} key={index}>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        padding: '8px',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        border: '1px solid black',
                                        backgroundColor: '#f5f5f5',
                                        fontFamily: 'Roboto, sans-serif',
                                        boxShadow: '0 2px 3px rgba(0,0,0,0.1)',
                                    }}
                                >
                                    <Typography gutterBottom>
                                        {slot.clientSlotStartTime} - {slot.clientSlotEndTime}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}

            {slots.length === 0 && !loading && (
                <Box sx={{ marginTop: '30px', textAlign: 'center' }}>
                    <Typography variant="h6" color="textSecondary">
                        No Slots Available
                    </Typography>
                </Box>
            )}
        </Box>

    );
};

export default SlotsInfoPage;
