import React, { useState } from 'react';
import { Box, Typography, TextField, MenuItem, Button, FormControl, InputLabel, Select, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { hasSuperAccess } from '../../utils/access';

export default function ViewTherapist() {
  const location = useLocation();

  // Sample data from location.state
  const data = location?.state || {
    centerAdmin: "",
    centerId: 0,
    centerName: "",
    centerPhone: "",
    emailId: "",
    empId: "",
    gender: "",
    therapistId: 0,
    therapistName: "",
    therapistPhone: "",
  };

  // Hardcoded centers data
  const centers = [
    { centerId: 1, centerName: "Pune Center" },
    { centerId: 2, centerName: "Gurgaon Center" },
    { centerId: 3, centerName: "Chandigarh Center" },
    { centerId: 4, centerName: "Kotla Center" },
    { centerId: 5, centerName: "Bangalore Center" },
    { centerId: 6, centerName: "Mumbai Center" },
    { centerId: 7, centerName: "Noida Center" },
    { centerId: 8, centerName: "Ludhiana Center" },
    { centerId: 9, centerName: "Hyderabad Center" },
    { centerId: 10, centerName: "Pitampura Center" },
    { centerId: 11, centerName: "Ahemdabad Center" },
    { centerId: 12, centerName: "Indore Center" },
    { centerId: 13, centerName: "Surat Center" },
  ];

  // State to store the form values
  const [therapistDetails, setTherapistDetails] = useState(data);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTherapistDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle reset
  const handleReset = () => {
    setTherapistDetails(data); // Reset to original state
  };

  // Handle form submission (API call can be added here)
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(therapistDetails); // This is where you can send the data to your API
  };

  return (
    <Container maxWidth="md" sx={{ padding: '40px', marginTop: '40px', boxShadow: 3, borderRadius: 2, backgroundColor: '#fff' }}>
      {/* Centered Heading */}
      <Typography variant="h4" align="center" gutterBottom>
        View Therapist Details
      </Typography>

      {/* Reset Button */}
      {hasSuperAccess() && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <Button color="secondary" onClick={handleReset}>
          Reset
        </Button>
      </Box>}

      {/* Therapist Form */}
      <form onSubmit={handleSubmit}>
        {/* First Row: Name, Email, Employee ID */}
        <Box sx={{ display: 'flex', gap: '20px', marginBottom: '40px' }}>
          <TextField
            fullWidth
            label="Therapist Name"
            name="therapistName"
            value={therapistDetails.therapistName}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Email"
            name="emailId"
            value={therapistDetails.emailId}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth
            label="Employee ID"
            name="empId"
            value={therapistDetails.empId}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        {/* Second Row: Phone, Center, Gender */}
        <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
          <TextField
            fullWidth
            label="Phone No."
            name="therapistPhone"
            value={therapistDetails.therapistPhone}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="center-select-label">Center</InputLabel>
            <Select
              labelId="center-select-label"
              name="centerId"
              value={therapistDetails.centerId}
              label="Center"
              onChange={handleChange}
            >
              {centers.map((center) => (
                <MenuItem key={center.centerId} value={center.centerId}>
                  {center.centerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <TextField
            fullWidth
            label="Gender"
            name="gender"
            value={therapistDetails.gender}
            onChange={handleChange}
          /> */}
          <FormControl fullWidth>
            <InputLabel id="gender-select-label">Gender</InputLabel>
            <Select
              labelId='gender-select-label'
              fullWidth
              label="Gender"
              name="gender"
              value={therapistDetails.gender}
              onChange={handleChange}
            >
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Centered Submit Button */}
        {hasSuperAccess() && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
          <Button variant="contained" color="primary" size="large" type="submit">
            Submit
          </Button>
        </Box>}
      </form>
    </Container>
  );
}