import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMachine } from '../../store/actions/machine.action';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import { Container, Box, Typography, Divider } from '@mui/material';
import TableLayout from '../../components/common/TherapistMachineProduct/TableLayout';
import SearchFilters from '../../components/common/TherapistMachineProduct/SearchFilters';

function MachineProductPage() {
  const dispatch = useDispatch();
  const machineList =
    useSelector((state) => state?.machine?.machineList?.machines) || [];
  const { isLoadingMachineList: loading } = useSelector(
    (state) => state?.machine,
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCenter, setSelectedCenter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  // Fetch machine data on component mount
  useEffect(() => {
    dispatch(fetchMachine());
  }, [dispatch]);

  // Filter machine list based on search query and selected center
  useEffect(() => {
    if (machineList.length > 0) {
      let filtered = machineList.filter(
        (machine) =>
          machine.machineName.toLowerCase().includes(searchQuery) ||
          machine.centerName.toLowerCase().includes(searchQuery) ||
          machine.machineId.toString().includes(searchQuery),
      );

      if (selectedCenter) {
        filtered = filtered.filter(
          (machine) => machine.centerName === selectedCenter,
        );
      }

      const updatedFiltered = filtered.map((item) => ({
        entityId: item.machineId,
        entityName: item.machineName,
        centerName: item.centerName,
        centerPhone: item.centerPhone,
        centerAdmin: item.centerAdmin,
      }));

      setFilteredData(updatedFiltered);
    } else {
      setFilteredData([]); // Reset filtered data if no machines
    }
  }, [machineList, searchQuery, selectedCenter]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Debugging: log machine list and loading state
  useEffect(() => {}, [machineList, loading]);

  // Loader or main content
  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        // margin: { xs: '10px', sm: '10px' },
        width: '100%',
        padding: '10px',
        bgcolor: 'rgba(0, 0, 255, 0.1)',
        borderRadius: '12px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      {/* Header Section */}
      <Box sx={{ textAlign: 'center', marginBottom: '2px' }}>
        <Typography
          variant="h4"
          sx={{ color: '#0077b6', fontWeight: 'bold', padding: '10px' }}
        >
          Machine Product
        </Typography>
      </Box>
      <Divider style={{ marginBottom: '20px', backgroundColor: '#0077b6' }} />

      {/* Search Filters */}
      <SearchFilters
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        selectedCenter={selectedCenter}
        setSelectedCenter={setSelectedCenter}
        entityList={machineList}
        label="Machine"
      />

      {/* Machine Table */}
      <TableLayout entityProducts={filteredData} label="Machine" />
    </Container>
  );
}

export default MachineProductPage;
