import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchProduct } from '../../../store/actions/product.action';
import { addMultiProductsToTherapist, removeProductsFromTherapist } from '../../../store/actions/therapist.action';
import { addMultiProductsToMachine, removeProductFromMachine } from '../../../store/actions/machine.action';

const useDialogLogic = (open, onClose, associatedId, label) => {
  const dispatch = useDispatch();

  // Access product list and loading/error states from Redux
  const { productList, isLoading: productLoading, error } = useSelector((state) => state.product);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [productsToDelete, setProductsToDelete] = useState([]);
  const [productsToAdd, setProductsToAdd] = useState([]);

  // Fetch products from the API via Redux when dialog opens
  useEffect(() => {
    if (open) {
      dispatch(fetchProduct());
      setProductsToAdd([]);
      setProductsToDelete([]);
    }
  }, [open, dispatch]);

  // Filter products based on search term
  useEffect(() => {
    const filtered = productList
      .filter((product) => 
        product.name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((product) => ({
        serviceName: product.name || '',
        productId: product.id || '',
        serviceGender: product.categoryGender || '',
      }));

    setFilteredProducts(filtered);
  }, [searchTerm, productList]);

  // Handle adding products to add list and toggle icons
  const handleAddProduct = (product) => {
    setProductsToAdd((prev) => {
      if (!prev.some((p) => p.productId === product.productId)) {
        return [...prev, product]; // Add product
      } else {
        return prev.filter((p) => p.productId !== product.productId); // Remove product
      }
    });
  };

  // Handle removing products
  const handleDeleteProduct = (productId) => {
    setProductsToDelete((prev) => {
      if (!prev.includes(productId)) {
        return [...prev, productId]; // Add to delete list
      } else {
        return prev.filter((p) => p !== productId); // Remove from delete list
      }
    });
  };

  // Handle submit: process both delete and add requests
  const handleSubmit = async () => {
    try {
      // Process delete requests
      if (productsToDelete.length > 0) {
        for (const productId of productsToDelete) {
          if (label === "Therapist") {
            await removeProductsFromTherapist(associatedId, productId); // Ensure this is a dispatch
          } else if (label === "Machine") {
            await removeProductFromMachine(associatedId, productId); // Ensure this is a dispatch
          }
        }
      }

      // Process add requests
      if (productsToAdd.length > 0) {
        if (label === "Therapist") {
          await addMultiProductsToTherapist(associatedId, productsToAdd); // Ensure this is a dispatch
        } else if (label === "Machine") {
          await addMultiProductsToMachine(associatedId, productsToAdd); // Ensure this is a dispatch
        }
      }

      // Notify success
      if (productsToAdd.length > 0 || productsToDelete.length > 0) {
        toast.success('Updated Successfully!');
      }
    } catch (error) {
      console.error(`Error updating products:`, error);
      toast.error(`Error updating products`);
    } finally {
      // Reset states after submission
      setProductsToDelete([]);
      setProductsToAdd([]);
      setSearchTerm('');
      onClose();
    }
  };

  return {
    filteredProducts,
    searchTerm,
    setSearchTerm,
    loading: productLoading,
    productsToDelete,
    setProductsToDelete,
    productsToAdd,
    setProductsToAdd,
    handleAddProduct,
    handleDeleteProduct,
    handleSubmit,
    error,
  };
};

export default useDialogLogic;