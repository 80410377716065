import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addTag } from '../../store/actions/tag.action';

const AddTagModal = ({ isOpen, onClose, onSuccess }) => {
    const userId = JSON.parse(localStorage.getItem('userData'))?.user?.id;
    const [tagText, setTagText] = useState('');
    const handleTagChange = (event) => {
        setTagText(event.target.value.toLowerCase());
    };

    const handleSubmit = async () => {
        try {
            const response = await addTag({ name: tagText, createdBy: userId, updatedBy: userId });
            if (response.status === 200) {
                toast.success('Tag added successfully!');
                setTagText('');
                onSuccess()
                onClose();

            } else {
                toast.error('Failed to add tag');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error adding tag');
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Add Tag</DialogTitle>
            <p style={{ color: 'red', marginLeft: '30px' }}>
                Tag name format must be like this <u>dummy</u> or <u>dummy-del</u>
            </p>
            <DialogContent>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        label="Enter Tag"
                        fullWidth
                        variant="outlined"
                        value={tagText}
                        onChange={handleTagChange}
                        inputProps={{ style: { textTransform: 'lowercase' } }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button onClick={handleSubmit} color="primary" variant="contained" disabled={!tagText}>
                    Submit
                </Button>
            </DialogActions>
            <ToastContainer />
        </Dialog>
    );
};

export default AddTagModal;
