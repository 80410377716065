import React, { useMemo } from 'react';
import { Box, TextField, InputAdornment, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchFilters = ({ searchQuery, handleSearch, selectedCenter, setSelectedCenter, selectedGender, setSelectedGender, entityList, label }) => {
  // Extract unique centers dynamically from entityList
  const uniqueCenters = useMemo(() => {
    const centers = entityList.map((therapist) => therapist.centerName);
    return ['All', ...new Set(centers)];
  }, [entityList]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  {/* Search Bar */}
  <TextField
    placeholder="Search..."
    variant="outlined"
    value={searchQuery}
    onChange={handleSearch}
    sx={{
      flex: '1 1 60%',
      borderRadius: '50px',
      bgcolor: 'white',
      boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.1)',
      height: '50px',
      mr: 2,
      '& .MuiOutlinedInput-root': {
        borderRadius: '50px',
        height: '50px',
        '& fieldset': {
          border: 'none',
        },
      },
      '&:hover .MuiOutlinedInput-root': {
        boxShadow: 'none',
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)',
      },
    }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />

  {/* Filters Section */}
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: '1 1 40%', gap: 2 }}>
    {/* Center Dropdown */}
    <TextField
      select
      label="Center"
      variant="outlined"
      value={selectedCenter}
      onChange={(e) => setSelectedCenter(e.target.value === 'All' ? '' : e.target.value)}
      sx={{
        width: '50%',
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
          backgroundColor: '#fff',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      {uniqueCenters.map((center, index) => (
        <MenuItem sx={center == 'All' ? {color: 'red'}: null} key={index} value={center}>
          {center === 'All' ? 'Clear Filter' : center}
        </MenuItem>
      ))}
    </TextField>

    {/* Gender Dropdown */}
    {label === "Therapist" && <TextField
      select
      label="Gender"
      variant="outlined"
      value={selectedGender}
      onChange={(e) => setSelectedGender(e.target.value === 'All' ? '' : e.target.value)}
      sx={{
        width: '50%',
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
          backgroundColor: '#fff',
          height: '50px',
          display: 'flex',
          alignItems: 'center', // Vertically center the placeholder and selected value
        },
      }}
    >
      <MenuItem value="All" sx={{color: 'red'}}>Clear Filter</MenuItem>
      <MenuItem value="male">Male</MenuItem>
      <MenuItem value="female">Female</MenuItem>
    </TextField>}
  </Box>
</Box>
  );
};

export default SearchFilters;