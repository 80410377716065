import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  CircularProgress,
  MenuItem,
  TextField,
  Stack,
  Pagination,
  Grid,
  Button,
} from '@mui/material';
import axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DownloadIcon from '@mui/icons-material/Download';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../components/common/userLocalStorageUtils';
import { splitDateTime } from '../../utils';

const newCityList = [
  { title: 'Delhi' },
  { title: 'Mumbai' },
  { title: 'Pune' },
  { title: 'Gurgaon' },
  { title: 'Noida' },
  { title: 'Chandigarh' },
  { title: 'Bangalore' },
  { title: 'Hyderabad' },
  { title: 'Ahmedabad' },
  { title: 'Indore' },
  { title: 'Ludhiana' },
  { title: 'Surat' },
];

const delayStatusOptions = [
  { value: '', label: 'All' },
  { value: 'on track', label: 'On Track' },
  { value: 'delayed and completed', label: 'Delayed And Completed' },
  { value: 'potential delay', label: 'Potential Delay' },
  { value: 'delayed', label: 'Delayed' },
];

const BookingTable = () => {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
  const [page, setPage] = useState(1);
  const [selectedCity, setSelectedCity] = useState('');
  const [delayStatus, setDelayStatus] = useState('');
  const [bookingList, setBookingList] = useState([]);
  const [filteredBookingList, setFilteredBookingList] = useState([]); // New filtered state
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [paginatedBookingList, setPaginatedBookingList] = useState([]);

  const itemsPerPage = 30;

  // Fetch data based on startDate, endDate, and selectedCity only
  const fetchBookings = async () => {
    setIsLoading(true);
    try {
      const params = { startDate, endDate };
      if (selectedCity) params.cityFilter = `'${selectedCity}'`;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/booking/all-booking-flag`,
        {
          params,
          headers: { token: getToken() },
        }
      );

      const bookings = response.data.bookings || [];
      setBookingList(bookings); // Set original data
      setTotalRecords(bookings.length);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, [startDate, endDate, selectedCity]);

  // Filter bookingList based on delayStatus without API call
  useEffect(() => {
    const filtered = bookingList.filter((data) => {
      const matchesDelayStatus = delayStatus ? data.delayedStatus === delayStatus : true;
      return matchesDelayStatus && data.delayedStatus !== 'MISSED';
    });

    const processedBookings = filtered.map((data) => {
      const formattedDate = splitDateTime(data.appointmentAt);
      const bookingDate = splitDateTime(data?.bookingAt);

      const capitalizedDelayedStatus = data.delayedStatus
        ? data.delayedStatus.toLowerCase().replace(/(?:^|\s)\S/g, (letter) => letter.toUpperCase())
        : '';

      return {
        'Service Id': data?.sessionSchedulesId,
        'Client Name': data.name || '',
        'Client Id': data?.clientId,
        Gender: data?.gender,
        'Phone Number': data.phoneNumber,
        City: data.city || '',
        'Service Name': data.productNames
          ? `${data.productNames} - ${data?.productGender}`
          : data.productName
            ? `${data.productName} - ${data?.productGender}`
            : '',
        'Service Date': formattedDate.date,
        'Service Time': formattedDate.time,
        Address: data.formattedAddress || '',
        Count: data.count || '',
        'Service Status': data.status || '',
        'Partner Name': data.partnerName || 'Not Assigned',
        Map: data.map || '',
        'Start Time': data.startTime || '',
        'End Time': data.endTime || '',
        'Booking Date': bookingDate?.date,
        'Booking Time': data?.bookingTime,
        'Reschedule Count': `(${data?.userRescheduleCount || 0})User / (${data?.dashboardRescheduleCount || 0})Dashboard`,
        'Custom Remark (RL)': data?.customRemarks || 'N/A',
        'Delayed Status': capitalizedDelayedStatus,
      };
    });

    setFilteredBookingList(processedBookings);
  }, [delayStatus, bookingList]);


  // Update paginated list whenever filteredBookingList or page changes
  useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedBookingList(filteredBookingList.slice(startIndex, endIndex));
  }, [filteredBookingList, page]);

  const fetchBookingforCSV = async (params) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/booking/get-delayed-booking-csv?startDate=${params.startDate}&endDate=${params.endDate}`,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
            token: getToken(),
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error('Error in fetchBookingforCSV:', error);
      throw error;
    }
  };

  const handleCSVButtonClick = async (format) => {
    try {
      setSubmitting(true);
      const data = await fetchBookingforCSV({ startDate, endDate });
      if (data?.downloadLinks) {
        const link = format === 'csv' ? data.downloadLinks.csv : data.downloadLinks.excel;
        if (link) {
          window.location.href = link;
        } else {
          alert('No valid download link available');
        }
      } else {
        alert('Error occurred while generating download link');
      }
    } catch (error) {
      console.error('Error fetching download link:', error);
      alert('An error occurred while fetching the download link. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getRowColor = (status) => {
    switch (status) {
      case 'Delayed':
        return 'rgba(255, 99, 71, 1)';
      case 'Potential Delay':
        return 'rgba(255, 223, 34, 0.6)';
      case 'Delayed And Completed':
        return 'rgba(255, 69, 0, 0.3)';
      case 'On Track':
      default:
        return '';
    }
  };

  return (
    <div>
      {/* Heading and Download Buttons */}
      <Typography variant="h4" align="center" gutterBottom>
        All Bookings
      </Typography>

      <Grid container spacing={2} mb={2} alignItems="center" justifyContent="space-between" px={{ xs: 2, md: 4 }}>
        {/* Legends */}
        <Grid item xs={12} md={8}>
          <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
            <Box display="flex" alignItems="center" gap={1}>
              <Box sx={{ width: 15, height: 15, bgcolor: 'rgba(255, 223, 34, 0.6)' }} />
              <Typography variant="body2">Potential Delay</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box sx={{ width: 15, height: 15, bgcolor: 'rgba(255, 69, 0, 0.3)' }} />
              <Typography variant="body2">Delayed And Completed</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box sx={{ width: 15, height: 15, bgcolor: 'rgba(255, 99, 71, 1)' }} />
              <Typography variant="body2">Delayed</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Box sx={{ width: 15, height: 15, bgcolor: '#ffffff' }} border="1px solid #ccc" />
              <Typography variant="body2">On Track</Typography>
            </Box>
          </Box>
        </Grid>

        {/* Download Buttons */}
        <Grid item xs={12} md={4}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={() => handleCSVButtonClick('csv')}
              disabled={submitting}
            >
              CSV
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<GetAppIcon />}
              onClick={() => handleCSVButtonClick('excel')}
              disabled={submitting}
            >
              Excel
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {/* Filters */}
      <Grid container spacing={2} mb={1} px={{ xs: 2, md: 4 }} justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={4} lg={3}>
          <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
            <Box>
              <small><label htmlFor="start-date">Start Date:</label></small>
              <input
                type="date"
                id="start-date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>
            <Box>
              <small><label htmlFor="end-date">End Date:</label></small>
              <input
                type="date"
                id="end-date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <TextField
            select
            label="Center"
            variant="outlined"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value === 'Clear' ? '' : e.target.value)}
            fullWidth
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <MenuItem value="Clear" sx={{ fontWeight: 'bold', color: '#FF0000' }}>
              Clear Filter
            </MenuItem>
            {newCityList.map((city, index) => (
              <MenuItem key={index} value={city.title} sx={{ color: '#333' }}>
                {city.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <TextField
            select
            label="Delay Status"
            variant="outlined"
            value={delayStatus}
            onChange={(e) => setDelayStatus(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            {delayStatusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {/* Total Bookings */}
      <Box mb={2} display="flex" justifyContent="center">
        <Typography variant="h6">
          {!isLoading && `Total Bookings: ${filteredBookingList.length}`}
        </Typography>
      </Box>

      {/* Table Section */}
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="40vh">
          <CircularProgress />
        </Box>
      ) : filteredBookingList.length > 0 && paginatedBookingList.length > 0 ? (
        <TableContainer component={Paper} style={{ maxWidth: '100vw', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'lightgray' }}>
                {Object.keys(paginatedBookingList[0] || {}).map((key) => (
                  <TableCell key={key} style={{ fontWeight: 'bold', whiteSpace: 'nowrap', fontSize: '12px' }}>
                    {key === 'Map' ? 'Location' : key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedBookingList.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  hover
                  onClick={() => navigate(`/booking-details/${row['Service Id']}`)}
                  style={{ backgroundColor: getRowColor(row['Delayed Status']) }}
                >
                  {Object.entries(row).map(([key, value], colIndex) => (
                    key === 'Map' ? (
                      <TableCell key={colIndex} style={{ fontSize: '12px' }}>
                        {value ? (
                          <LocationOnIcon style={{ cursor: 'pointer' }} onClick={() => window.open(value, '_blank')} />
                        ) : 'N/A'}
                      </TableCell>
                    ) : (
                      <TableCell key={colIndex} style={{ fontSize: '12px' }}>
                        {value !== null ? value : 'N/A'}
                      </TableCell>
                    )
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" align="center" color="textSecondary">
          No bookings found.
        </Typography>
      )}

      {/* Pagination */}
      {filteredBookingList.length > itemsPerPage && !isLoading && (
        <Stack spacing={2} alignItems="center" mt={3}>
          <Pagination
            count={Math.ceil(filteredBookingList.length / itemsPerPage)}
            color="primary"
            page={page}
            onChange={handlePageChange}
          />
        </Stack>
      )}
    </div>
  );
};

export default BookingTable;
