import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { styled } from '@mui/system';

const CenterCard = ({ center, handleEdit, access }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  const handleLocationClick = (lat, long) => {
    window.open(`https://www.google.com/maps?q=${lat},${long}`, '_blank');
  };

  const FixedHeightCard = styled(Card)(({ theme }) => ({
    height: '300px',
    position: 'relative',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      // transform: 'translateY(-5px)',
      boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    },
  }));

  const StyledAddress = styled(Typography)(({ expanded }) => ({
    // overflow: expanded ? 'visible' : 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: expanded ? 'normal' : 'nowrap',
    cursor: 'default',
    color: '#555',
  }));

  return (
    <FixedHeightCard
      sx={{
        backgroundColor: '#f5f5g6',
        borderRadius: '12px',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      {access && <IconButton
        onClick={() => handleEdit(center)}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          // backgroundColor: '#0077b6',
          color: 'gray',
          '&:hover': {
            // backgroundColor: '#005f8a',
            color: '#0077b6'
          },
        }}
      >
        <EditIcon />
      </IconButton>}

      <CardContent>
        {/* Center Name */}
        <Typography variant="h6" gutterBottom sx={{ color: '#0077b6', fontWeight: 'bold' }}>
          {center['Center Name']}
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>Center ID:</strong> {center['Id']}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Admin Name:</strong> {center['Admin Name']}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Admin Phone:</strong> {center['Admin Phone']}
        </Typography>

        {/* Address - Not Clickable */}
        <StyledAddress expanded={expanded} variant="body1" onClick={toggleExpand}>
          <strong>Address:</strong> {center['Address']}
        </StyledAddress>

        <Typography variant="body1" gutterBottom sx={{marginTop: '5px'}}>
          <strong>Timings:</strong> {center['Start Time']} - {center['End Time']}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{marginTop: '5px'}}>
          <strong>Location:</strong> <small>{center['Lat']} - {center['Long']}</small>
          <IconButton
            onClick={() => handleLocationClick(center['Lat'], center['Long'])}
            sx={{ color: '#0077b6', marginBottom: '5px'}}
          >
            <LocationOnIcon />
          </IconButton>
        </Typography>
      </CardContent>
    </FixedHeightCard>
  );
};

export default CenterCard;
