import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { hasAdminAndSuperAdminAccess } from '../../components/common/UserRolesConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import { hasSuperAccess } from '../../utils/access';

const MachineTable = ({ machinesList, requestDetail, handleDelete,  handleTagButton,
  openModalButton }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCenter, setSelectedCenter] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [machineMap, setMachineMap] = useState(new Map());

  const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
  const id = JSON.parse(localStorage.getItem('userData'))?.user?.id;

  // Step 1: Create a map with machineName as key and index as value
  useEffect(() => {
    const newMap = new Map();
    machinesList.forEach((machine, index) => {
      newMap.set(machine.machineName.toLowerCase(), index);
    });
    setMachineMap(newMap);
  }, [machinesList]);

  // Step 2: Handle search and filter the machines
  const filteredMachines = useMemo(() => {
    const matchingIndices = [];

    // Check for matching machine names
    machineMap.forEach((index, machineName) => {
      if (machineName.includes(searchQuery.toLowerCase())) {
        matchingIndices.push(index);
      }
    });

    // Filter machines based on selected center and matched machine names
    return machinesList.filter(
      (machine, index) =>
        (selectedCenter ? machine.centerName === selectedCenter : true) &&
        matchingIndices.includes(index),
    );
  }, [searchQuery, selectedCenter, machineMap, machinesList]);

  // Memoized handlers for the request and delete operations
  const handleRequestClick = useCallback(
    (machine) => {
      requestDetail(machine);
    },
    [requestDetail],
  );

  const openDeleteDialog = useCallback((machine) => {
    setSelectedMachine(machine);
    setOpenDialog(true);
  }, []);

  const closeDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const confirmDelete = useCallback(() => {
    if (selectedMachine) {
      handleDelete(selectedMachine.machineId);
      toast.success(
        `Machine ${selectedMachine.machineName} (ID: ${selectedMachine.machineId}) deleted successfully!`,
      );
    }
    setOpenDialog(false);
  }, [handleDelete, selectedMachine]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCenterChange = (e) => {
    setSelectedCenter(e.target.value === 'All' ? '' : e.target.value);
  };

  const uniqueCenters = useMemo(
    () => [
      'All',
      ...new Set(machinesList.map((machine) => machine.centerName)),
    ],
    [machinesList],
  );

  const handleOpenTagButton=(row)=>{
    handleTagButton(row)
  }
  return (
    <>
      {machinesList.length > 0 ? (
        <Box sx={{ padding: '20px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography
              variant="h6"
              align="left"
              gutterBottom
              sx={{ alignSelf: 'flex-end' }}
            >
              <span style={{ fontWeight: 600, fontSize: '20px' }}>
                Total machines:{' '}
              </span>
              {filteredMachines.length}
            </Typography>

            {/* Search and Filter Container */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {/* Search Input */}
              <TextField
                placeholder="Search Machine"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{
                  borderRadius: '50px',
                  bgcolor: 'white',
                  boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.1)',
                  height: '50px',
                  maxWidth: '400px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '50px',
                    height: '50px',
                    '& fieldset': {
                      border: 'none',
                    },
                  },
                  '&:hover .MuiOutlinedInput-root': {
                    boxShadow: 'none',
                  },
                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              {/* Center Filter Dropdown */}
              <TextField
                select
                label="Center"
                variant="outlined"
                value={selectedCenter}
                onChange={handleCenterChange}
                sx={{
                  width: '150px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                {uniqueCenters.map((center, index) => (
                  <MenuItem
                    key={index}
                    value={center}
                    sx={center === 'All' ? { color: 'red' } : null}
                  >
                    {center === 'All' ? 'Clear Filter' : center}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>

          <TableContainer
            component={Paper}
            sx={{ maxHeight: 800, overflow: 'auto' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: '600' }}>Machine ID</TableCell>
                  <TableCell sx={{ fontWeight: '600' }}>Machine Name</TableCell>
                  <TableCell sx={{ fontWeight: '600' }}>Center Name</TableCell>
                  <TableCell sx={{ fontWeight: '600' }}>Center Phone</TableCell>
                  <TableCell sx={{ fontWeight: '600' }}>Center Admin</TableCell>
                  <TableCell sx={{ fontWeight: '600' }}>
                    Repair/Unavailable
                  </TableCell>
                  {hasSuperAccess(id) && (
                    <TableCell sx={{ fontWeight: '600' }}>Actions</TableCell>
                  )}
                   <TableCell sx={{ fontWeight: '600' }}>
                   Add Tag
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMachines.map((machine) => (
                  <TableRow key={machine.machineId}>
                    <TableCell>{machine.machineId}</TableCell>
                    <TableCell>{machine.machineName}</TableCell>
                    <TableCell>{machine.centerName}</TableCell>
                    <TableCell>{machine.centerPhone}</TableCell>
                    <TableCell>{machine.centerAdmin}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRequestClick(machine)}
                      >
                        Request
                      </Button>
                    </TableCell>
                    {hasSuperAccess(id) && (
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => openDeleteDialog(machine)}
                          sx={{
                            backgroundColor: 'red',
                            '&:hover': {
                              backgroundColor: 'red',
                            },
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    )}
                    {openModalButton && (
                    <TableCell className="table-cell">
                      {openModalButton &&
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>handleOpenTagButton(machine)}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {openModalButton}
                        </Button>
                      }
                    </TableCell>
                  )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Confirmation Dialog */}
          <Dialog open={openDialog} onClose={closeDialog}>
            <DialogTitle
              sx={{ textAlign: 'center', color: 'red', fontWeight: 600 }}
            >
              Confirm Deletion
            </DialogTitle>
            <Divider style={{ backgroundColor: '#0077b6' }} />
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the machine <br />
                <b>{selectedMachine?.machineName}</b> (ID:{' '}
                {selectedMachine?.machineId}) from{' '}
                <b>{selectedMachine?.centerName}</b>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeDialog}
                color="primary"
                sx={{ backgroundColor: 'lightblue' }}
              >
                No
              </Button>
              <Button
                onClick={confirmDelete}
                color="secondary"
                sx={{
                  color: 'red',
                  '&:hover': { backgroundColor: 'red', color: 'white' },
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <ToastContainer />
        </Box>
      ) : (
        <LoaderComponent />
      )}
    </>
  );
};

export default MachineTable;
