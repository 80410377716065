import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCenter } from '../../store/actions/center.action';
import { fetchTherapistAvailability, makeFreeSlotForHalfanHour } from '../../store/actions/SchedulerAnalytics.action';
import { centerAction } from '../../store/slices/centerSlice';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { markTherapistFree, markTherapistWeekOffFree } from '../../store/actions/therapist.action';
import './slotModal.style.css';
import SlotModal from './SlotModal';
import './therapistAnalyticsV2.style.css'
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';

const TherapistAnalyticsV2 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeOption, setActiveOption] = useState(0);
    const [centerId, setCenterId] = useState(4);
    const centerList = useSelector(state => state.center?.centerList?.centers || []);
    const selectedId = useSelector(state => state.center?.centerId);
    const selectDate = useSelector(state => state.center?.selectDate);
    const therapists = useSelector(state => state.schedulerAnalytics?.therapistAnalytics?.centerTherapist || []);
    const isLoadingTherapist = useSelector(state => state.schedulerAnalytics?.isLoadingTherapistAnalytics);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    // const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10))
    const [selectedDate, setSelectedDate] = useState(selectDate.date);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedCell, setSelectedCell] = useState({ therapistId: null, timeRange: null });

    const selectedCellRef = useRef(null);

    const handleClick = (timeRange) => {
        setSelectedCell({ therapistId: timeRange.therapistId, timeRange: timeRange.time });
        setSelectedSlot(timeRange);
        setModalVisible(true);
    };

    const handleClose = () => {
        setModalVisible(false);
        setSelectedSlot(null);
    };

    const handleFree = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('userData'))
            const res = await makeFreeSlotForHalfanHour({
                time: selectedSlot.time,
                serviceId: selectedSlot.serviceId,
                therapistId: selectedSlot.therapistId,
                date: selectedDate,
                therapistName: selectedSlot.therapistName,
                userId: user?.user?.id,
                username: user?.user?.name
            });
            if (res?.status?.code === 200) {
                alert(res.status?.message)
                handleClose();
                dispatch(fetchTherapistAvailability({ selectedDate, selectedId }));
                // window.location.reload()
            }
        } catch (err) {
            console.error('An error occurred while making the slot free:', err);
            return err;
        }
    };
    
    useEffect(() => {
        if (selectedCellRef.current) {
            selectedCellRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [therapists]);

    useEffect(() => {
        dispatch(fetchCenter());
        setCenterId(selectedId);
        setActiveOption(selectDate.index);
    }, [dispatch]);

    useEffect(() => {
        setCenterId(selectedId);
        dispatch(fetchTherapistAvailability({ selectedDate, selectedId }));
    }, [selectedId, selectedDate]);

    const handleChange = (event) => {
        const selectedCenterId = event.target.value;
        setCenterId(selectedCenterId);
        dispatch(fetchTherapistAvailability({ selectedDate, selectedId }))
        dispatch(centerAction.addCenterId(selectedCenterId));
    };

    const user = JSON.parse(localStorage.getItem("userData"))
    const userId = user?.user?.id
    const username = user?.user?.name


    function getNextSevenDays(today) {
        const dates = [];
        for (let i = 0; i < 90; i++) {
            const nextDate = new Date(today);
            nextDate.setDate(today.getDate() + i);
            const year = nextDate.getFullYear();
            const month = String(nextDate.getMonth() + 1).padStart(2, '0');
            const day = String(nextDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            dates.push({ id: i + 1, date: formattedDate });
        }
        return dates;
    }

    const handleActive = (index, item, date) => {
        setActiveOption(index);
        setSelectedDate(date)
        dispatch(centerAction.addDate({ index, day: item, date }));
    };
    const handleMarkFreeTherapist = async (id, name) => {
        try {
            const isConfirmed = window.confirm(`Are you sure you want to Mark ${name} free on ${selectedDate}?`);
            if (isConfirmed) {
                setIsButtonDisabled(true);
                console.log(userId, username, user)
                const body = {
                    date: selectedDate,
                    therapistId: id,
                    userId,
                    username,
                    therapistName: name
                };
                const res = await markTherapistFree(body);
                if (res?.status === 200) {
                    alert(res.data?.status?.message);
                    window.location.reload();
                } else {
                    alert(`An error occurred while marking ${name} free.`)
                    setIsButtonDisabled(false);
                }
            }
        } catch (error) {
            setIsButtonDisabled(false);
            console.error('An error occurred while handling the submission:', error);
        }
    }

    const markFreeTherapistWeekOfF = async (id, name) => {
        try {
            const isConfirmed = window.confirm(`Are you sure you want to Mark ${name} free on ${selectedDate}?`);
            if (isConfirmed) {
                setIsButtonDisabled(true);
                const body = {
                    date: selectedDate,
                    therapistId: id
                };
                const res = await markTherapistWeekOffFree(body);
                if (res?.status === 200) {
                    alert(res.data?.status?.message);
                    window.location.reload();
                } else {
                    alert("An error occurred while mark free therapist.")
                    setIsButtonDisabled(false);
                }
            }
        } catch (error) {
            setIsButtonDisabled(false);
            console.error('An error occurred while handling the submission:', error);
        }
    }
    const timeSlots = [
        '07:00-07:30', '07:30-08:00',
        '08:00-08:30', '08:30-09:00', '09:00-09:30', '09:30-10:00', '10:00-10:30', '10:30-11:00',
        '11:00-11:30', '11:30-12:00', '12:00-12:30', '12:30-13:00',
        '13:00-13:30', '13:30-14:00', '14:00-14:30', '14:30-15:00',
        '15:00-15:30', '15:30-16:00', '16:00-16:30', '16:30-17:00',
        '17:00-17:30', '17:30-18:00', '18:00-18:30', '18:30-19:00',
        '19:00-19:30', '19:30-20:00', '20:00-20:30', '20:30-21:00',
        '21:00-21:30', '21:30-22:00'
    ];

    const renderSlots = (availability, therapist) => {
        const slotMap = {};

        availability.forEach(slot => {
            const startTime = moment(slot.startTime, 'HH:mm');
            const endTime = moment(slot.endTime, 'HH:mm');
            const duration = moment.duration(endTime.diff(startTime));
            const slotCount = duration.asMinutes() / 30;

            for (let i = 0; i < slotCount; i++) {
                const startTimeFormatted = startTime.clone().add(i * 30, 'minutes').format('HH:mm');
                const endTimeFormatted = startTime.clone().add((i + 1) * 30, 'minutes').format('HH:mm');
                const timeRange = `${startTimeFormatted}-${endTimeFormatted}`;

                if (slot.status === "LEAVE") {
                    slotMap[timeRange] = <span onClick={() => handleClick({ time: timeRange, therapistId: therapist.therapistId, therapistName: therapist.therapistName })} style={{ backgroundColor: 'gray', color: 'black', padding: '8px', padding: '8px', borderRadius: '10px' }}>Leave</span>;
                } else if (slot.status === "AVAILABLE") {
                    slotMap[timeRange] = <span style={{ backgroundColor: '#01FF00', color: 'black', padding: '8px', whiteSpace: 'nowrap', borderRadius: '10px' }}>Available</span>;
                } else if (slot.status === "UNAVAILABLE") {
                    slotMap[timeRange] = <span onClick={() => handleClick({ time: timeRange, therapistId: therapist.therapistId, therapistName: therapist.therapistName })} style={{ backgroundColor: 'gray', color: 'black', padding: '8px', whiteSpace: 'nowrap', borderRadius: '10px' }}>Unavailable</span>;
                } else if (slot.status === "SESSION_BLOCKED") {
                    slotMap[timeRange] = <span onClick={() => handleClick({ time: timeRange, therapistId: therapist.therapistId, therapistName: therapist.therapistName, serviceId: slot.serviceId })} style={{ backgroundColor: 'red', color: 'black', padding: '8px', whiteSpace: 'nowrap', borderRadius: '10px', cursor: 'pointer' }} >Service ID:{slot.serviceId}</span>;
                } else if (slot.status === "BUFFER_START") {
                    slotMap[timeRange] = <span onClick={() => handleClick({ time: timeRange, therapistId: therapist.therapistId, therapistName: therapist.therapistName, serviceId: slot.serviceId })} style={{ backgroundColor: 'red', color: 'black', padding: '8px', whiteSpace: 'nowrap', borderRadius: '10px', cursor: 'pointer' }} >Service ID:{slot.serviceId}</span>;
                } else if (slot.status === "BUFFER_END") {
                    slotMap[timeRange] = <span onClick={() => handleClick({ time: timeRange, therapistId: therapist.therapistId, therapistName: therapist.therapistName, serviceId: slot.serviceId })} style={{ backgroundColor: 'red', color: 'black', padding: '8px', whiteSpace: 'nowrap', borderRadius: '10px', cursor: 'pointer' }} >Service ID:{slot.serviceId}</span>;
                }
                else if (slot.status === "OFF_HOUR") {
                    slotMap[timeRange] = <span onClick={() => handleClick({ time: timeRange, therapistId: therapist.therapistId, therapistName: therapist.therapistName, serviceId: slot.serviceId })} style={{ color: 'black', padding: '8px', backgroundColor: 'gray', borderRadius: '10px' }}>Off&nbsp;hours</span>
                }
                else {
                    slotMap[timeRange] = 'error';
                }
            }
        });

        return timeSlots.map(time => {
            const isSelectedCell = selectedCell.therapistId === therapist.therapistId && selectedCell.timeRange === time;
            return (
            <TableCell key={time} align="center" ref={isSelectedCell ? selectedCellRef : null} sx={{ borderRight: 1, borderColor: 'divider' }}>
                {slotMap[time] || (Object.keys(slotMap).length !== 0 ? <span style={{ color: 'black', padding: '8px', backgroundColor: 'gray', borderRadius: '10px' }}>NA&nbsp;</span> : <span style={{ backgroundColor: 'gray', color: 'black', padding: '8px', borderRadius: '10px' }}>Week&nbsp;Off</span>)}
            </TableCell>
        )});
    }

    return (
        <Box sx={{ width: '100%', padding: '10px', top: '64px' }} position='sticky'>
            <Typography variant="h7" style={{ display: 'flex', marginBottom: '15px', fontWeight: 'bold' }}>Choose Your Center and Pick a Date Below</Typography>
            <FormControl fullWidth required>
                <InputLabel id="center-label">Centers</InputLabel>
                <Select
                    labelId="center-label"
                    id="centerId"
                    name="centerId"
                    value={centerId}
                    onChange={handleChange}
                    label="Center"
                    sx={{ height: '40px' }}
                >
                    <MenuItem value="">
                        <em>Select Center</em>
                    </MenuItem>
                    {centerList.map(center => (
                        <MenuItem key={center.id} value={center.id}>
                            {center.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="date-picker-container">
                <ul className="date-picker-list">
                    {getNextSevenDays(new Date())?.map((item, index) => (
                        <li
                            key={index}
                            className={`date-picker-item ${activeOption === index ? 'active' : ''}`}
                            onClick={() => handleActive(index, moment(item.date).format('dddd'), item.date)}
                        >
                            <span style={{ display: 'inline', whiteSpace: 'nowrap' }}>{moment(item.date).format('MMMM ddd DD')}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <Typography variant="h6" style={{ display: 'flex', marginTop: '10px', marginBottom: '20px' }}>Total No of Therapists: {therapists.length}</Typography>
            {isLoadingTherapist ? <LoaderComponent /> : <TableContainer component={Paper} sx={{ maxHeight: '550px', overflow: 'auto' }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="therapist availability table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ borderRight: 1, borderColor: 'divider', fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 2 }}>Therapist&nbsp;Name</TableCell>
                            {timeSlots.map((slot, index) => (
                                <TableCell key={index} align="center" sx={{ borderRight: 1, borderColor: 'divider', fontWeight: 'bold', whiteSpace: "nowrap", top: 0, position: 'sticky', backgroundColor: 'white', zIndex: 1 }}>
                                    {slot}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {therapists?.map((therapist, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" align="center" sx={{ borderRight: 1, borderColor: 'divider', position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 }}>
                                    {`${therapist.therapistId}-${therapist.therapistName}`}
                                    {(therapist?.availability.some(slot => slot.status === "LEAVE" || slot.status === "REPAIR" || slot.status === "UNAVAILABLE")) &&
                                        <Button
                                            style={{
                                                fontWeight: 'bold',
                                                marginTop: '5px',
                                                backgroundColor: 'pink',
                                                borderRadius: "7px",
                                                cursor: 'pointer',
                                                padding: '10px',
                                                marginLeft: '5px'
                                            }}
                                            disabled={isButtonDisabled}
                                            onClick={() => handleMarkFreeTherapist(therapist.therapistId, therapist.therapistName)}
                                        >
                                            Mark Free
                                        </Button>}
                                </TableCell>
                                {renderSlots(therapist?.availability || [], therapist)}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}

            <div>
                {modalVisible && (
                    <SlotModal
                        timeRange={selectedSlot.time}
                        serviceId={selectedSlot.serviceId}
                        therapistId={selectedSlot.therapistId}
                        therapistName={selectedSlot.therapistName}
                        // day={selectedDay}
                        onClose={handleClose}
                        onFree={handleFree}
                    />
                )}
            </div>
        </Box>
    );
};

export default TherapistAnalyticsV2;


