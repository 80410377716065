import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    TextField,
    Autocomplete,
    Stack,
    IconButton,
    Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { addOrDeleteTags, getItemTag } from '../../store/actions/tag.action';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxWidth: '400px',
    width: '100%',
};

const AddDeleteTagModal = ({ open, onClose, itemData, type }) => {
    const userId = JSON.parse(localStorage.getItem('userData'))?.user?.id;
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagsToAdd, setTagsToAdd] = useState(new Set());
    const [tagsToDelete, setTagsToDelete] = useState(new Set());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const itemId = type === "machine" ? itemData?.machineId : type === "therapist" ? itemData?.therapistId : itemData?.id;
    const itemName = type === "machine" ? itemData?.machineName : type === "therapist" ? itemData?.therapistName : itemData?.name;
    const dispatch = useDispatch();
    const itemTagList = useSelector((state) => state.tags?.itemTagList || []);
    useEffect(() => {
        dispatch(getItemTag({ type, itemId })).then((response) => {
            const tags = response.payload;
            if (Array.isArray(tags)) {
                const preSelectedTags = tags.filter(tag => Boolean(tag.alreadyAdded));
                setSelectedTags(preSelectedTags);
            } else {
                console.error("Expected tags to be an array, but received:", tags);
            }
        });
    }, [dispatch, itemId, type]);

    const handleTagChange = (event, newValue) => {
        const newTagsToAdd = new Set(tagsToAdd);
        const newTagsToDelete = new Set(tagsToDelete);

        newValue.forEach(tag => {
            if (!selectedTags.some(selectedTag => selectedTag.id === tag.id)) {
                newTagsToAdd.add(tag.id);
                newTagsToDelete.delete(tag.id);
            }
        });

        selectedTags.forEach(tag => {
            if (!newValue.some(selectedTag => selectedTag.id === tag.id)) {
                newTagsToDelete.add(tag.id);
                newTagsToAdd.delete(tag.id);
            }
        });
        setTagsToAdd(newTagsToAdd);
        setTagsToDelete(newTagsToDelete);
        setSelectedTags(newValue);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        try {
            await addOrDeleteTags({
                type: type,
                id: itemId,
                tagsToAdd: Array.from(tagsToAdd),
                tagsToDelete: Array.from(tagsToDelete),
                userId: userId
            });
            toast.success('Tag added successfully!');
            onClose();
        } catch (error) {
            console.error('Error updating tags:', error);
            alert('Failed to update tags. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
        <Modal open={open} onClose={onClose} aria-labelledby="tag-assignment-modal">
            <Box sx={style}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Assign Tags</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
                    <strong>ID: </strong>{itemId}<br />
                    <strong>Name: </strong>{itemName}
                </Typography>
                <Autocomplete
                    multiple
                    options={itemTagList.filter(tag => !selectedTags.some(selectedTag => selectedTag.id === tag.id))}
                    getOptionLabel={(option) => option.name}
                    value={selectedTags}
                    onChange={handleTagChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Tags" placeholder="Tags" />
                    )}
                    renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => (
                            <Chip
                                key={index}
                                label={option.name}
                                {...getTagProps({ index })}
                                color="primary"
                                variant="outlined"
                            />
                        ))
                    }
                    disableCloseOnSelect
                    sx={{ mb: 3 }}
                />
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    sx={{
                        mt: 2,
                        bgcolor: '#007BFF',
                        color: '#fff',
                        '&:hover': {
                            bgcolor: '#0056B3',
                        },
                    }}
                >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
            </Box>
        </Modal>
         <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
         </>
    );
};

export default AddDeleteTagModal;
