import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const FormFields = ({ formData, handleChange, cityList, adminList }) => {
    return (
        <>
            {/* Center Name */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Center Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </Grid>

            {/* Center Phone */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Center Phone"
                    name="phone"
                    type="tel"
                    value={formData.phone}
                    onChange={handleChange}
                    inputProps={{ pattern: '[0-9]{10}', title: 'Please enter a 10-digit phone number' }}
                    required
                />
            </Grid>

            {/* Center Address */}
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Center Address"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    required
                />
            </Grid>

            {/* Latitude */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Latitude"
                    name="lat"
                    value={formData.lat}
                    onChange={handleChange}
                    required
                />
            </Grid>

            {/* Longitude */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Longitude"
                    name="long"
                    value={formData.long}
                    onChange={handleChange}
                    required
                />
            </Grid>

            {/* Start Time */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Start Time"
                    name="startTime"
                    type="time"
                    value={formData.timings.startTime}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 3600 }}
                    required
                />
            </Grid>

            {/* End Time */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="End Time"
                    name="endTime"
                    type="time"
                    value={formData.timings.endTime}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 3600 }}
                    required
                />
            </Grid>

            {/* City Dropdown */}
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel id="city-select-label">City</InputLabel>
                    <Select
                        labelId="city-select-label"
                        id="cityId"
                        name="cityId"
                        value={formData.cityId}
                        onChange={handleChange}
                        label="City"
                        required
                    >
                        <MenuItem value="">
                            <em>Select City</em>
                        </MenuItem>
                        {cityList?.map((city) => (
                            <MenuItem key={city.id} value={city.id}>
                                {city.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {/* Admin Dropdown */}
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel id="admin-select-label">Center Admin</InputLabel>
                    <Select
                        labelId="admin-select-label"
                        id="adminUserId"
                        name="adminUserId"
                        value={formData.adminUserId}
                        onChange={handleChange}
                        label="Center Admin"
                        required
                    >
                        <MenuItem value="">
                            <em>Select Admin</em>
                        </MenuItem>
                        {adminList?.map((admin) => (
                            <MenuItem key={admin.id} value={admin.id}>
                                {admin.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {/* Admin Name */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Admin Name"
                    name="adminName"
                    value={formData.adminName}
                    disabled
                />
            </Grid>

            {/* Admin Phone */}
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Admin Phone"
                    name="adminPhone"
                    value={formData.adminPhone}
                    disabled
                />
            </Grid>
        </>
    );
};

export default FormFields;
