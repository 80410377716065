import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Box, Typography, Fade } from '@mui/material';
import BookingTable from './BookingTable';
import Delayed from './Delayed';
import MissedBookingsPage from './MissedOrders';

const SectionThree = () => (
  <Typography variant="h4" align="center" style={{ padding: '20px' }}>
    something
  </Typography>
);

// Main component with top bar and sections
const BookingOverview = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const handleTabChange = (event, newValue) => {
    // Trigger fade-out before changing tab
    setFadeIn(false);
    setTimeout(() => {
      setActiveTab(newValue);
      setFadeIn(true);
    }, 200);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <BookingTable />;
      case 1:
        return <MissedBookingsPage />;
      default:
        return null;
    }
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <div>
      {/* Top bar with Tabs */}
      <AppBar position="static" style={{ backgroundColor: '#1976d2' }}>
        <Tabs value={activeTab} onChange={handleTabChange} 
        indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth">
          <Tab label="Booking Overview" {...a11yProps(0)}/>
          {/* <Tab label="Delayed" {...a11yProps(1)} /> */}
          <Tab label="Missed orders" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      {/* Animated content section */}
      <Fade in={fadeIn} timeout={{ enter: 500, exit: 200 }}>
        <Box mt={2}>{renderContent()}</Box>
      </Fade>
    </div>
  );
};

export default BookingOverview;
