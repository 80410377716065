import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import DialogComponent from './DialogComponent';

const TableLayout = ({ entityProducts, label }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Open the pop-up on row click
  const handleRowClick = (row) => {
    console.log("Row cilcked: ", row);
    setSelectedRow(row);
    setDialogOpen(true);
  };

  // Close the dialog pop-up
  const handleClose = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  return (
    <>
      <Typography variant="body1">
        <span style={{ fontWeight: 600, fontSize: '18px' }}>Total {label.toLowerCase()}: </span> {entityProducts.length}
      </Typography>
      <TableContainer component={Paper} sx={{ borderRadius: '8px', boxShadow: 'none', overflowY: 'auto', minHeight: 450, marginTop: '10px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>{label} ID</TableCell>
              <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>{label} Name</TableCell>
              {label === 'Therapist' && <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>Therapist Gender</TableCell>}
              <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>Center Admin</TableCell>
              <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>Center Name</TableCell>
              <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>Center Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entityProducts.length > 0 ? (
              entityProducts.map((product) => (
                <TableRow
                  key={product.entityId} // Use product, not entityProducts
                  hover
                  onClick={() => handleRowClick(product)} // Pass the individual product
                  sx={{ cursor: 'pointer', transition: 'background-color 0.3s', '&:hover': { bgcolor: '#e0e0e0' } }}
                >
                  <TableCell>{product.entityId}</TableCell> {/* Access product fields */}
                  <TableCell>{product.entityName}</TableCell> {/* Access product fields */}
                  {label === "Therapist" && <TableCell>{product.gender}</TableCell>}
                  <TableCell>{product.centerAdmin}</TableCell>
                  <TableCell>{product.centerName}</TableCell>
                  <TableCell>{product.centerPhone}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                  No Match Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog Component */}
      {selectedRow && <DialogComponent open={dialogOpen} onClose={handleClose} selectedRow={selectedRow} label={label} />}
    </>
  );
};

export default TableLayout;
