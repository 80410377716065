import React, { useEffect, useState } from 'react';
import { Typography, Checkbox, TextField, Button, Grid, FormControlLabel, Box, Paper, Divider, MenuItem, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCenter } from '../../../store/actions/center.action';
import { addTherapist } from '../../../store/actions/therapist.action';
import { useNavigate } from 'react-router-dom';

const AddTherapist = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    // Therapist details state
    const [therapistDetails, setTherapistDetails] = useState({
        name: '',
        employeeId: '',
        centerId: '',
        phone: '',
        email: '',
        isRockstar: false,
        gender: '',
        products: [],
    });

    // Availability state
    const [availability, setAvailability] = useState({
        mondayAvailability: null,
        tuesdayAvailability: null,
        wednesdayAvailability: null,
        thursdayAvailability: null,
        fridayAvailability: null,
        saturdayAvailability: null,
        sundayAvailability: null,
    });

    // Fetching centers from Redux store
    const centers = useSelector(state => state.center?.centerList?.centers);
    const genders = ['Male', 'Female', 'Other'];

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTherapistDetails({
            ...therapistDetails,
            [name]: value,
        });
    };

    // Handle availability input changes
    const handleAvailabilityChange = (day, field = null, value = null, weekOff = false) => {
        if (weekOff) {
            setAvailability((prevState) => ({
                ...prevState,
                [day]: [], // Mark as week off
            }));
        } else {
            setAvailability((prevState) => ({
                ...prevState,
                [day]: {
                    ...prevState[day],
                    [field]: value,
                },
            }));
        }
    };

    // Form submission handler
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation: Check required fields
        if (!therapistDetails.name || !therapistDetails.employeeId || !therapistDetails.phone || !therapistDetails.email || !therapistDetails.gender || !therapistDetails.centerId) {
            alert('Please fill all required therapist details.');
            return;
        }

        // Validate availability times
        for (const day of Object.keys(availability)) {
            const dayAvailability = availability[day];
            if (dayAvailability !== null && !Array.isArray(dayAvailability)) {
                if (!dayAvailability.startTime || !dayAvailability.endTime) {
                    alert(`Please select both start time and end time for ${day.replace('Availability', '')} or mark it as a week off.`);
                    return;
                }
                if (dayAvailability.startTime >= dayAvailability.endTime) {
                    alert(`End time must be later than start time for ${day.replace('Availability', '')}.`);
                    return;
                }
            }
        }

        // Prepare payload for submission
        const payload = {
            ...therapistDetails,
            products: JSON.stringify(therapistDetails.products),
            ...availability,
        };

        // API call to add therapist
        setLoading(true);
        const res = await addTherapist(payload);
        if (res?.status === 200) {
            alert(res.data.status?.message);
            // Navigate to therapist list (assuming this is part of your navigation flow)
            navigate("/therapistlist");
        }
        setLoading(false);
    };

    // Time picker rendering function
    const renderTimePicker = (dayKey, label, field) => (
        <TextField
            label={label}
            select
            value={availability[dayKey]?.[field] || ''}
            onChange={(e) => handleAvailabilityChange(dayKey, field, e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
        >
            {Array.from({ length: 31 }, (_, i) => {
                const hour = String(Math.floor((i + 14) / 2)).padStart(2, '0');
                const minutes = (i + 14) % 2 === 0 ? '00' : '30';
                const timeValue = `${hour}:${minutes}`;
                return (
                    <MenuItem key={timeValue} value={timeValue}>
                        {timeValue}
                    </MenuItem>
                );
            })}
        </TextField>
    );

    // Fetch centers when component mounts
    useEffect(() => {
        dispatch(fetchCenter());
    }, [dispatch]);

    return (
        <Paper elevation={4} style={{ padding: '30px', marginTop: '30px', backgroundColor: '#f0f4f7' }}>
            <Typography variant="h4" gutterBottom style={{ color: '#0077b6', fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>
                Add New Therapist Info
            </Typography>

            <Box marginBottom={4}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Name"
                                name="name"
                                value={therapistDetails.name}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Employee ID"
                                name="employeeId"
                                value={therapistDetails.employeeId}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Phone"
                                name="phone"
                                value={therapistDetails.phone}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Email"
                                name="email"
                                value={therapistDetails.email}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                select
                                label="Center"
                                name="centerId"  // Using centerId here
                                value={therapistDetails.centerId}  // Bound to centerId
                                onChange={handleChange}
                                fullWidth
                                required
                            >
                                {centers?.map((center) => (
                                    <MenuItem key={center.id} value={center.id}> {/* Using center.id */}
                                        {center.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                select
                                label="Gender"
                                name="gender"
                                value={therapistDetails.gender}
                                onChange={handleChange}
                                fullWidth
                                required
                            >
                                {genders.map((gender) => (
                                    <MenuItem key={gender} value={gender}>
                                        {gender}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Divider style={{ margin: '30px 0', backgroundColor: '#0077b6' }} />

                    {/* Availability Section */}
                    <Grid container spacing={4}>
                        {Object.keys(availability).map((dayKey) => (
                            <Grid item xs={12} md={6} key={dayKey}>
                                <Box padding="20px" borderRadius="8px" bgcolor="#ffffff" sx={{ boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.1)' }}>
                                    <Typography variant="h6" gutterBottom style={{ textTransform: 'capitalize', color: '#023047', fontWeight: '600' }}>
                                        {dayKey.replace('Availability', '')}
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Array.isArray(availability[dayKey])}
                                                onChange={(e) => handleAvailabilityChange(dayKey, null, null, e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Week Off"
                                    />
                                    {!Array.isArray(availability[dayKey]) && (
                                        <>
                                            <Grid container spacing={2} style={{ marginTop: '10px' }}>
                                                <Grid item xs={6}>
                                                    {renderTimePicker(dayKey, 'Start Time', 'startTime')}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {renderTimePicker(dayKey, 'End Time', 'endTime')}
                                                </Grid>
                                            </Grid>
                                            <Divider style={{ margin: '10px 0' }} />
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <Divider style={{ margin: '30px 0', backgroundColor: '#0077b6' }} />

                    <Button
                        type="submit"
                        variant="contained"
                        style={{
                            backgroundColor: '#0077b6',
                            color: '#ffffff',
                            padding: '10px 20px',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            display: 'block',
                            margin: '0 auto',
                        }}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </form>
            </Box>
        </Paper>
    );
};

export default AddTherapist;
