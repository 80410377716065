import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Paper, Divider, MenuItem } from '@mui/material';
import { getToken } from '../userLocalStorageUtils';

const TherapistAvailability = ({ partnerId }) => {
    const [availability, setAvailability] = useState({
        mondayAvailability: null,
        tuesdayAvailability: null,
        wednesdayAvailability: null,
        thursdayAvailability: null,
        fridayAvailability: null,
        saturdayAvailability: null,
        sundayAvailability: null,
    });

    const [originalData, setOriginalData] = useState({});
    const [staticAvailability, setStaticAvailability] = useState({}); // For displaying static availability blocks

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/partner/get-availability/${partnerId}`, {
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                token: getToken(),
            }
        })
            .then(response => {
                const data = response.data.availability;
                setOriginalData(data);
                setStaticAvailability(data); // Set the static availability data
                initializeAvailabilityState(data);
            })
            .catch(error => {
                console.error('Error fetching availability data:', error);
            });
    }, [partnerId]);

    const initializeAvailabilityState = (data) => {
        const updatedState = {};
        Object.keys(data).forEach(dayKey => {
            if (data[dayKey].length === 0) {
                updatedState[dayKey] = []; // Mark as week off
            } else {
                const startSlot = data[dayKey].find(slot => slot.status !== 'OFF_HOUR');
                const endSlot = [...data[dayKey]].reverse().find(slot => slot.status !== 'OFF_HOUR');
                if (startSlot && endSlot) {
                    updatedState[dayKey] = {
                        startTime: startSlot.startTime,
                        endTime: endSlot.endTime,
                    };
                }
            }
        });
        setAvailability(prevState => ({
            ...prevState,
            ...updatedState,
        }));
    };

    const handleAvailabilityChange = (dayKey, newStartTime = null, newEndTime = null, weekOff = false) => {
        if (weekOff) {
            setAvailability(prevState => ({
                ...prevState,
                [dayKey]: [] // Set as an empty array indicating week off
            }));
        } else {
            setAvailability(prevState => ({
                ...prevState,
                [dayKey]: {
                    startTime: newStartTime !== null ? newStartTime : prevState[dayKey]?.startTime || '09:00',
                    endTime: newEndTime !== null ? newEndTime : prevState[dayKey]?.endTime || '17:00',
                }
            }));
        }
    };

    const generateTimeSlotsArray = (dayKey, startTime, endTime) => {
        const newAvailability = [];
        let currentTime = "07:00";
        
        // Step 1: Create new availability slots based on the user input
        while (currentTime < "22:00") {
            let nextTime = addMinutes(currentTime, 30);
            if (currentTime >= startTime && nextTime <= endTime) {
                newAvailability.push({
                    status: 'AVAILABLE',
                    blocked: false,
                    startTime: currentTime,
                    endTime: nextTime,
                    serviceId: null,
                });
            } else {
                newAvailability.push({
                    status: 'OFF_HOUR',
                    blocked: true,
                    startTime: currentTime,
                    endTime: nextTime,
                    serviceId: null,
                });
            }
            currentTime = nextTime;
        }

        // Step 2: Merge the newly created availability slots with the original data
        const mergedAvailability = [];
        currentTime = "07:00";
        while (currentTime < "22:00") {
            let nextTime = addMinutes(currentTime, 30);
            const originalSlot = originalData[dayKey].find(slot => slot.startTime === currentTime && slot.endTime === nextTime);
            const newSlot = newAvailability.find(slot => slot.startTime === currentTime && slot.endTime === nextTime);

            if (originalSlot) {
                // If the original slot has a status other than "OFF_HOUR" within the range, preserve it
                if (originalSlot.status !== 'OFF_HOUR' && (currentTime >= startTime && nextTime <= endTime)) {
                    mergedAvailability.push(originalSlot);
                } else {
                    // Otherwise, take the new slot (which is either OFF_HOUR or AVAILABLE)
                    mergedAvailability.push(newSlot);
                }
            } else {
                // If no original slot is found, use the newly created slot
                mergedAvailability.push(newSlot);
            }

            currentTime = nextTime;
        }

        // Step 3: Ensure complete coverage from 7 AM to 10 PM, filling gaps with "OFF_HOUR" slots
        const finalAvailability = [];
        currentTime = "07:00";
        while (currentTime < "22:00") {
            let nextTime = addMinutes(currentTime, 30);
            const slot = mergedAvailability.find(slot => slot.startTime === currentTime && slot.endTime === nextTime);

            if (slot) {
                finalAvailability.push(slot);
            } else {
                // If a slot is missing, add it as "OFF_HOUR"
                finalAvailability.push({
                    status: 'OFF_HOUR',
                    blocked: true,
                    startTime: currentTime,
                    endTime: nextTime,
                    serviceId: null,
                });
            }

            currentTime = nextTime;
        }

        return finalAvailability;
    };

    const addMinutes = (time, minsToAdd) => {
        const [hours, minutes] = time.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes + minsToAdd;
        const newHours = String(Math.floor(totalMinutes / 60)).padStart(2, '0');
        const newMinutes = String(totalMinutes % 60).padStart(2, '0');
        return `${newHours}:${newMinutes}`;
    };

    const handleSubmit = () => {
        const payload = {};
        Object.keys(availability).forEach(dayKey => {
            if (availability[dayKey] === null) {
                payload[dayKey] = null;
            } else if (Array.isArray(availability[dayKey]) && availability[dayKey].length === 0) {
                payload[dayKey] = [];
            } else {
                const { startTime, endTime } = availability[dayKey];
                payload[dayKey] = generateTimeSlotsArray(dayKey, startTime, endTime);
            }
        });
        console.log("Payload:", payload);

        axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/partner/update-availability/${partnerId}`, payload, {
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                token: getToken(),
            }
        })
            .then(response => {
                console.log('Availability updated successfully:', response);
                alert('Availability updated successfully');
                window.location.reload();
            })
            .catch(error => {
                console.error('Error updating availability:', error);
                alert('Error updating availability:');
                window.location.reload();
            });
    };

    const renderTimePicker = (dayKey, label, timeType) => {
        return (
            <TextField
                label={label}
                select
                value={availability[dayKey][timeType]}
                onChange={(e) =>
                    handleAvailabilityChange(dayKey, timeType === 'startTime' ? e.target.value : null, timeType === 'endTime' ? e.target.value : null)
                }
                InputLabelProps={{ shrink: true }}
                fullWidth
            >
                {/* Filter options to only show times from 07:00 to 22:00 */}
                {Array.from({ length: 24 }, (_, hour) => (
                    ['00', '30'].map((minute) => {
                        const timeValue = `${String(hour).padStart(2, '0')}:${minute}`;
                        // Only include times between 07:00 and 22:00
                        if (timeValue >= '07:00' && timeValue <= '22:00') {
                            return (
                                <MenuItem key={timeValue} value={timeValue}>
                                    {timeValue}
                                </MenuItem>
                            );
                        }
                        return null; // Skip times outside the range
                    })
                ))}
            </TextField>
        );
    };

    // Render the static availability blocks
    const renderStaticAvailabilityBlocks = (dayKey) => {
        const dayData = staticAvailability[dayKey];
        if (!dayData) return null;

        return (
            <Grid container spacing={1}>
                {dayData.map((slot, index) => (
                    <Grid item key={index}>
                        <Box
                            padding="10px"
                            borderRadius="4px"
                            style={{
                                backgroundColor: slot.status === 'OFF_HOUR' ? '#d3d3d3' : slot.status === 'AVAILABLE' ? '#4caf50' : '#f44336',
                                color: '#ffffff',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="caption">
                                {slot.startTime} - {slot.endTime}
                                {slot.status !== 'OFF_HOUR' && slot.status !== 'AVAILABLE' ? ` (ID: ${slot.serviceId})` : ''}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <Paper elevation={4} style={{ padding: '30px', marginTop: '30px', backgroundColor: '#f0f4f7' }}>
            <Typography variant="h4" gutterBottom style={{ color: '#0077b6', fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>
                Therapist Availability Scheduler
            </Typography>
            <Grid container spacing={4}>
                {Object.keys(availability).map(dayKey => (
                    <Grid item xs={12} md={6} key={dayKey}>
                        <Box
                            padding="20px"
                            borderRadius="8px"
                            bgcolor="#ffffff"
                            sx={{ boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.1)' }}
                        >
                            <Typography variant="h6" gutterBottom style={{ textTransform: 'capitalize', color: '#023047', fontWeight: '600' }}>
                                {dayKey.replace('Availability', '')}
                            </Typography>
                            {availability[dayKey] === null ? (
                                <Typography variant="body2" color="textSecondary">
                                    Loading...
                                </Typography>
                            ) : (
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Array.isArray(availability[dayKey])}
                                                onChange={(e) =>
                                                    handleAvailabilityChange(dayKey, null, null, e.target.checked)
                                                }
                                                color="primary"
                                            />
                                        }
                                        label="Week Off"
                                    />
                                    {!Array.isArray(availability[dayKey]) && (
                                        <>
                                            <Grid container spacing={2} style={{ marginTop: '10px' }}>
                                                <Grid item xs={6}>
                                                    {renderTimePicker(dayKey, "Start Time", "startTime")}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {renderTimePicker(dayKey, "End Time", "endTime")}
                                                </Grid>
                                            </Grid>
                                            <Divider style={{ margin: '10px 0' }} />
                                            <Box style={{ marginTop: '10px' }}>
                                                {renderStaticAvailabilityBlocks(dayKey)}
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Divider style={{ margin: '30px 0', backgroundColor: '#0077b6' }} />
            <Button
                variant="contained"
                style={{
                    backgroundColor: '#0077b6',
                    color: '#ffffff',
                    padding: '10px 20px',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    display: 'block',
                    margin: '0 auto',
                }}
                onClick={handleSubmit}
            >
                Submit
            </Button>
        </Paper>
    );
};

export default TherapistAvailability;
