import React, { useState } from 'react';
import { Dialog, MenuItem, Select, Button } from '@mui/material';
import './addProductModal.style.css';

const ProductAddModal = ({ open, handleClose, products, onSubmit }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductChange = (event) => {
    setSelectedProducts(event.target.value);
  };

  const handleFormSubmit = () => {
    onSubmit(selectedProducts);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="add-product-modal">
        <div className="add-product-modal-content">
          <span className="add-product-close" onClick={handleClose}>
            &times;
          </span>
          <h3 className="add-product-title">Add Products</h3>
          <Select
            multiple
            value={selectedProducts}
            onChange={handleProductChange}
            displayEmpty
            className="add-product-select"
          >
            <MenuItem disabled value="">
              <em>Select Products</em>
            </MenuItem>
            {products.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            className="add-product-submit-button"
            onClick={handleFormSubmit}
            disabled={selectedProducts.length === 0}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ProductAddModal;
