import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTherapist } from '../../store/actions/therapist.action';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import { ToastContainer } from 'react-toastify';
import SearchFilters from '../../components/common/TherapistMachineProduct/SearchFilters';
import TableLayout from '../../components/common/TherapistMachineProduct/TableLayout';

const TherapistProductPage = () => {

  const dispatch = useDispatch();
  
  let therapistList = useSelector(state => state?.therapist?.therapistList?.therapists) || [];
  let isTherapistListLoading = useSelector(state => state?.therapist?.isTherapistListLoading);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCenter, setSelectedCenter] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(fetchTherapist());
  }, [dispatch]);

  useEffect(() => {
    let filtered = therapistList.filter(
      (therapist) =>
        therapist.therapistName.toLowerCase().includes(searchQuery) ||
        therapist.centerName.toLowerCase().includes(searchQuery) ||
        therapist.gender?.toLowerCase().includes(searchQuery) ||
        therapist.therapistId.toString().includes(searchQuery)
    );

    if (selectedCenter) {
      filtered = filtered.filter(therapist => therapist.centerName === selectedCenter);
    }

    if (selectedGender) {
      filtered = filtered.filter(therapist => therapist.gender.toLowerCase() === selectedGender);
    }

    let updatedFiltered = filtered.map((item) => ({
      entityId: item.therapistId,
      centerId: item.centerId,
      gender: item.gender,
      entityName: item.therapistName,
      centerName: item.centerName,
      centerPhone: item.centerPhone,
      centerAdmin: item.centerAdmin,
    }));

    setFilteredData(updatedFiltered);
  }, [therapistList, searchQuery, selectedCenter, selectedGender]);

  // Search handler
  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  return (
    <>
      {isTherapistListLoading ? (
        <LoaderComponent />
      ) : (
        <Container
          maxWidth = {false}
          sx={{
            // margin: { xs: '10px', sm: '10px' },
            width: '100%',
            padding: '10px',
            bgcolor: 'rgba(0, 0, 255, 0.1)',
            borderRadius: '12px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          }}
        >
          {/* Header Section */}
          <Box sx={{ textAlign: 'center', marginBottom: '2px' }}>
            <Typography variant="h4" sx={{ color: '#0077b6', fontWeight: 'bold', padding: '10px' }}>
              Therapist Product
            </Typography>
          </Box>
          <Divider style={{ marginBottom: '20px', backgroundColor: '#0077b6' }} />
          {/* Search Filters */}
          <SearchFilters
            searchQuery={searchQuery}
            handleSearch={handleSearch}
            selectedCenter={selectedCenter}
            setSelectedCenter={setSelectedCenter}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
            entityList={therapistList}
            label="Therapist"
          />

          {/* Therapist Table */}
          {filteredData ? <TableLayout entityProducts={filteredData} label="Therapist" />: null}
        </Container>
      )}
      <ToastContainer 
      />
    </>
  );
};

export default TherapistProductPage;
