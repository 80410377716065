import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addTherapistUnavailabilityAndLeave, fetchTherapist } from '../../store/actions/therapist.action';
import { fetchCenter } from '../../store/actions/center.action';
import { fetchBookingsByPartner } from '../../store/actions/booking.action';
import { splitDateTime } from '../../utils';
import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import TableComponent from '../../components/common/TableComponent/TableComponent';

const AddTherapistUnavailability = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filteredTherapistList, setFilteredTherapistList] = useState([]);
    const therapistList = useSelector(state => state?.therapist?.therapistList?.therapists || []);
    const centerList = useSelector(state => state.center?.centerList?.centers || []);
    const [bookingList, setBookingList] = useState([]);
    const [errors, setErrors] = useState({ startTime: '', endTime: '' });

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const todayFormattedDate = today.toISOString().split('T')[0];
    const nextNinetyDays = new Date(new Date().setDate(today.getDate() + 90)).toISOString().split('T')[0];

    const [formData, setFormData] = useState({
        therapistId: '',
        startDate: todayFormattedDate,
        endDate: tomorrow.toISOString().split('T')[0],
        startTime: '',
        endTime: '',
        type: '',
        centerId: '',
        therapistName: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        dispatch(fetchCenter());
        dispatch(fetchTherapist());
    }, [dispatch]);

    useEffect(() => {
        if (formData.centerId) {
            const filteredTherapists = therapistList.filter(therapist => parseInt(therapist.centerId) === parseInt(formData.centerId));
            setFilteredTherapistList(filteredTherapists);
        } else {
            setFilteredTherapistList(therapistList);
        }
    }, [formData.centerId, therapistList]);

    const handleBookingDetail = (details) => {
        navigate(`/booking-details/${details['Service Id']}`);
    };

    const isValidTime = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return (
            hours >= 7 &&
            hours <= 22 &&
            (minutes === 0 || minutes === 30)
        );
    };

    const validateTimes = () => {
        let valid = true;
        const newErrors = { startTime: '', endTime: '' };

        if (!isValidTime(formData.startTime)) {
            newErrors.startTime = 'Start time must be between 7:00 AM and 10:00 PM in 30-minute intervals.';
            valid = false;
        }

        if (!isValidTime(formData.endTime)) {
            newErrors.endTime = 'End time must be between 7:00 AM and 10:00 PM in 30-minute intervals.';
            valid = false;
        }

        if (formData.startTime && formData.endTime && formData.startTime >= formData.endTime) {
            newErrors.endTime = 'End time must be after the start time.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });

        if (name === "startTime" || name === "endTime") {
            setErrors({ ...errors, [name]: '' });  // Clear error on re-entry
        }

        if (name === "therapistId") {
            const selectedTherapist = filteredTherapistList.find(therapist => therapist.id === parseInt(value));
            setFormData({
                ...formData,
                therapistId: value,
                therapistName: selectedTherapist ? selectedTherapist.name : ''
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.type == 'UNAVAILABLE' && !validateTimes()) {
            alert(formData.type)
            alert("Please enter time in proper format.");
            return;
        }

        setIsSubmitting(true);
        const reqBody = formData.type === "LEAVE"
            ? {
                therapistId: parseInt(formData?.therapistId),
                startDate: formData.startDate,
                endDate: formData.endDate,
                type: formData.type,
            }
            : {
                therapistId: parseInt(formData?.therapistId),
                startDate: formData.startDate,
                type: formData.type,
                startTime: formData.startTime + ":00",
                endTime: formData.endTime + ":00",
            };
        const res = await addTherapistUnavailabilityAndLeave(reqBody);
        if (res?.status?.code === 200) {
            alert(res.status?.message);
            navigate("/allrequestlist");
        } else {
            alert(res);
            window.location.reload();
        }
        setIsSubmitting(false);
    };

    const fetchBookingData = async () => {
        const obj = {
            startDate: formData.startDate,
            endDate: formData.endDate,
            page: 1,
            searchType: "partnerName",
            searchText: formData.therapistName
        };
        const res = await fetchBookingsByPartner(obj);
        if (res.status.code === 200) {
            const formattedBookings = res.bookings.map(data => {
                const formattedDate = splitDateTime(data.appointmentAt);
                const bookingDate = splitDateTime(data.bookingAt);
                return {
                    'Service Id': data?.sessionSchedulesId,
                    'Client Name': data.name || '',
                    'Gender': data?.gender,
                    'Phone Number': data.phoneNumber,
                    'City': data.city || '',
                    'Service Name': data.productName || '',
                    'Service Date': formattedDate.date,
                    'Service Time': formattedDate.time,
                    'Service Status': data.status || '',
                    'Partner Name': data.partnerName || 'Not Assigned',
                    "Start Time": data.startTime || "",
                    "End Time": data.endTime || "",
                    "Comment": data.comment || "",
                    "Booking Date": bookingDate?.date,
                    "Booking Time": data?.bookingTime
                };
            });
            setBookingList(formattedBookings);
        }
    };

    useEffect(() => {
        if (formData?.therapistName && formData?.centerId) {
            fetchBookingData();
        }
    }, [formData.therapistName, formData.startDate, formData.endDate]);

    return (
        <Box sx={{ p: 2, maxWidth: '800px', mx: 'auto' }}>
            <Typography variant="h4" sx={{ textAlign: 'center', mb: 3 }}>Add Unavailable/Leave Request</Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                    label="Center"
                    name="centerId"
                    select
                    value={formData.centerId}
                    onChange={handleChange}
                    required
                    fullWidth
                >
                    <MenuItem value="">Select Center</MenuItem>
                    {centerList.map(center => (
                        <MenuItem key={center.id} value={center.id}>{center.name}</MenuItem>
                    ))}
                </TextField>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Therapist"
                            name="therapistId"
                            select
                            value={formData.therapistId}
                            onChange={handleChange}
                            required
                            fullWidth
                        >
                            <MenuItem value="">Select Therapist</MenuItem>
                            {filteredTherapistList.map(therapist => (
                                <MenuItem key={therapist.therapistId} value={therapist.therapistId}>{therapist.therapistName}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Leave Type"
                            name="type"
                            select
                            value={formData.type}
                            onChange={handleChange}
                            required
                            fullWidth
                        >
                            <MenuItem value="">Select Type</MenuItem>
                            <MenuItem value="LEAVE">LEAVE</MenuItem>
                            <MenuItem value="UNAVAILABLE">UNAVAILABLE</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Start Date"
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                            required
                            fullWidth
                            InputProps={{ inputProps: { min: todayFormattedDate, max: nextNinetyDays } }}
                        />
                    </Grid>
                    {formData.type === "LEAVE" && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="End Date"
                                type="date"
                                name="endDate"
                                value={formData.endDate}
                                onChange={handleChange}
                                required
                                fullWidth
                                InputProps={{ inputProps: { min: formData.startDate, max: nextNinetyDays } }}
                            />
                        </Grid>
                    )}
                </Grid>

                {formData.type === "UNAVAILABLE" && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Start Time"
                                type="time"
                                name="startTime"
                                value={formData.startTime}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.startTime}
                                helperText={errors.startTime}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="End Time"
                                type="time"
                                name="endTime"
                                value={formData.endTime}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.endTime}
                                helperText={errors.endTime}
                            />
                        </Grid>
                    </Grid>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    sx={{
                        backgroundColor: isSubmitting ? 'gray' : 'primary.main',
                        cursor: isSubmitting ? 'not-allowed' : 'pointer'
                    }}
                >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
            </Box>

            <Typography variant="h5" sx={{ mb: 2 }}>Therapist History</Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                    <TextField type="date" disabled value={formData.startDate} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField type="date" disabled value={formData.endDate} fullWidth />
                </Grid>
            </Grid>

            <TableComponent
                data={bookingList}
                viewBookingButton={'View'}
                bookingDetails={handleBookingDetail}
            />
        </Box>
    );
};

export default AddTherapistUnavailability;
