import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  CircularProgress,
  MenuItem,
  TextField,
  Button,
  Stack,
  Pagination,
  Grid,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DownloadIcon from '@mui/icons-material/Download';
import GetAppIcon from '@mui/icons-material/GetApp';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../components/common/userLocalStorageUtils';
import { splitDateTime } from '../../utils';

const newCityList = [
  { title: 'Delhi' },
  { title: 'Mumbai' },
  { title: 'Pune' },
  { title: 'Gurgaon' },
  { title: 'Noida' },
  { title: 'Chandigarh' },
  { title: 'Bangalore' },
  { title: 'Hyderabad' },
  { title: 'Ahmedabad' },
  { title: 'Indore' },
  { title: 'Ludhiana' },
  { title: 'Surat' },
];

const MissedBookingsPage = () => {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [endDate, setEndDate] = useState(tomorrow.toISOString().split('T')[0]);
  const [page, setPage] = useState(1);
  const [selectedCity, setSelectedCity] = useState('');
  const [bookingList, setBookingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const itemsPerPage = 30;

  const fetchBookings = async () => {
    setIsLoading(true);
    try {
      const params = { startDate, endDate };
      if (selectedCity) {
        params.cityFilter = `'${selectedCity}'`;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/booking/all-missed-booking-flag`, {
        headers: { token: getToken() },
        params,
      });

      const bookings = response.data.bookings || [];
      const filteredBookings = bookings.filter((item) => (
        item.delayedStatus === 'MISSED'
      ))
      setBookingList(filteredBookings);
      setTotalRecords(filteredBookings.length);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBookingforCSV = async (params) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/booking/get-missed-booking-csv?startDate=${params.startDate}&endDate=${params.endDate}`,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
            token: getToken(),
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error('Error in fetchBookingforCSV:', error);
      throw error;
    }
  };

  const handleCSVButtonClick = async (format) => {
    try {
      setSubmitting(true);
      const data = await fetchBookingforCSV({ startDate, endDate });
      if (data?.downloadLinks) {
        const link = format === 'csv' ? data.downloadLinks.csv : data.downloadLinks.excel;
        if (link) {
          window.location.href = link;
        } else {
          alert('No valid download link available');
        }
      } else {
        alert('Error occurred while generating download link');
      }
    } catch (error) {
      console.error('Error fetching download link:', error);
      alert('An error occurred while fetching the download link. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, [startDate, endDate, selectedCity]);

  const paginatedBookingList = bookingList.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleMapRedirect = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const processedBookingList = paginatedBookingList.map((data) => {
    const formattedDate = splitDateTime(data.appointmentAt);
    const bookingDate = splitDateTime(data?.bookingAt);

    const capitalizedDelayedStatus = data.delayedStatus
      ? data.delayedStatus.toLowerCase().replace(/(?:^|\s)\S/g, (letter) => letter.toUpperCase())
      : '';

    return {
      'Service Id': data?.sessionSchedulesId,
      'Client Name': data.name || '',
      'Client Id': data?.clientId,
      Gender: data?.gender,
      'Phone Number': data.phoneNumber,
      City: data.city || '',
      'Service Name': data.productNames
        ? `${data.productNames} - ${data?.productGender}`
        : data.productName
          ? `${data.productName} - ${data?.productGender}`
          : '',
      'Service Date': formattedDate.date,
      'Service Time': formattedDate.time,
      Address: data.formattedAddress || '',
      Count: data.count || '',
      'Service Status': data.status || '',
      'Partner Name': data.partnerName || 'Not Assigned',
      Map: data.map || '',
      'Start Time': data.startTime || '',
      'End Time': data.endTime || '',
      'Booking Date': bookingDate?.date,
      'Booking Time': data?.bookingTime,
      'Reschedule Count': `(${data?.userRescheduleCount || 0})User / (${data?.dashboardRescheduleCount || 0})Dashboard`,
      'Custom Remark (RL)': data?.customRemarks || 'N/A',
      'Delayed Status': capitalizedDelayedStatus,
    };
  });

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Missed Bookings
      </Typography>

      <Stack direction="row" spacing={2} mb={3} justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
          onClick={() => handleCSVButtonClick('csv')}
          disabled={submitting}
        >
          CSV
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<GetAppIcon />}
          onClick={() => handleCSVButtonClick('excel')}
          disabled={submitting}
        >
          Excel
        </Button>
      </Stack>

      {/* Filter Section */}
      <Grid container spacing={2} mb={3} px={{ xs: 2, md: 4 }} alignItems="center">
        <Grid item xs={12} md={4}>
          <Box display="flex" gap={2} flexDirection="row" alignItems="center">
            <Box>
              <small><label htmlFor="start-date">Start Date:</label></small>
              <input
                type="date"
                id="start-date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={endDate}
                style={{ width: '100%' }}
              />
            </Box>
            <Box>
              <small><label htmlFor="end-date">End Date:</label></small>
              <input
                type="date"
                id="end-date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          {processedBookingList.length > 0 && (
            <Typography variant="h6" align="center" gutterBottom>
              {!isLoading && `Total bookings: ${totalRecords}`}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            select
            label="Center"
            variant="outlined"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value === 'Clear' ? '' : e.target.value)}
            fullWidth
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <MenuItem value="Clear" sx={{ fontWeight: 'bold', color: '#FF0000' }}>
              Clear Filter
            </MenuItem>
            {newCityList.map((city, index) => (
              <MenuItem key={index} value={city.title} sx={{ color: '#333' }}>
                {city.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="40vh">
          <CircularProgress />
        </Box>
      ) : processedBookingList.length > 0 ? (
        <TableContainer component={Paper} sx={{ maxWidth: '100vw', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: 'lightgray' }}>
                {Object.keys(processedBookingList[0]).map((key) => (
                  <TableCell key={key} sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', fontSize: '12px' }}>
                    {key === 'Map' ? 'Location' : key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {processedBookingList.map((row, rowIndex) => (
                <TableRow key={rowIndex} hover onClick={() => navigate(`/booking-details/${row['Service Id']}`)}>
                  {Object.entries(row).map(([key, value], colIndex) => (
                    key === 'Map' ? (
                      <TableCell key={colIndex} sx={{ fontSize: '12px' }}>
                        {value ? (
                          <LocationOnIcon sx={{ cursor: 'pointer' }} onClick={() => handleMapRedirect(value)} />
                        ) : 'N/A'}
                      </TableCell>
                    ) : (
                      <TableCell key={colIndex} sx={{ fontSize: '12px' }}>
                        {value !== null ? value : 'N/A'}
                      </TableCell>
                    )
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" align="center" color="textSecondary">
          No bookings found.
        </Typography>
      )}

      {totalRecords > itemsPerPage && !isLoading && (
        <Stack spacing={2} alignItems="center" mt={3}>
          <Pagination
            count={Math.ceil(totalRecords / itemsPerPage)}
            color="primary"
            page={page}
            onChange={handlePageChange}
          />
        </Stack>
      )}
    </div>
  );
};

export default MissedBookingsPage;
