import React, { useEffect, useState } from 'react';
import TableComponent from '../../components/common/TableComponent/TableComponent';
import Stack from '@mui/material/Stack';
import { Button, Typography, Modal, Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTag, getTag } from '../../store/actions/tag.action';
import AddTagModal from './AddTagModal';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import EditTagModal from './EditTagModal';

const TagList = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditModalOpen,setIsEditModalOpen]=useState(false)
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const tagList = useSelector((state) => state.tags?.tagList?.allTag);
    const pageCount = useSelector((state) => state.tags?.tagList?.totalPage);
    const isLoading = useSelector((state) => state.tags?.tagListLoading);
    const [tagData, setTagData] = useState();

    useEffect(() => {
        dispatch(getTag(page));
    }, [dispatch, page]);

    const handleAddTagSuccess = () => {
        dispatch(getTag(page));
    };

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const handleEditModalOpen = () => setIsEditModalOpen(true);
    const handleEditModalClose = () => setIsEditModalOpen(false);
    const handleDeleteModalClose = () => setDeleteModalOpen(false);

    const handlePageChange = (event, value) => setPage(value);

    const handleDelete = async (id) => {
        try {
            await deleteTag(id);
            setDeleteModalOpen(false);
            dispatch(getTag(page));
        } catch (error) {
            console.error('Error deleting tag:', error);
        }
    };

    const handleUpdate = (data) => {
        if (data) {
            setTagData(data);
            setDeleteModalOpen(true);
        }
    };

    const handleViewDetails = (data) => {
        handleEditModalOpen()
        setTagData(data);
    };

    return (
        <div>
            <Typography
                variant="h4"
                align="center"
                gutterBottom
                style={{ color: '#0077b6', fontWeight: 'bold', marginBottom: '20px' }}
            >
                Tags
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <Button variant="contained" color="primary" onClick={handleModalOpen}>
                    Add Tag
                </Button>
            </div>

            {isLoading ? (
                <LoaderComponent />
            ) : (
                <TableComponent
                    data={tagList}
                    showUpdateButton="Delete"
                    updateDetails={handleUpdate}
                    viewButton="Edit"
                    viewDetails={handleViewDetails}
                />
            )}

            {!isLoading && (
                <div className="incentiv-pagination" style={{ marginTop: '1rem' }}>
                    <Stack spacing={3}>
                        <Pagination
                            count={pageCount}
                            page={page}
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Stack>
                </div>
            )}
            <AddTagModal isOpen={isModalOpen} onClose={handleModalClose} onSuccess={handleAddTagSuccess}/>
            <EditTagModal isOpen={isEditModalOpen} onClose={handleEditModalClose} onSuccess={handleAddTagSuccess} data={tagData}/>
            <Modal
                open={isDeleteModalOpen}
                onClose={handleDeleteModalClose}
                aria-labelledby="delete-confirmation-modal"
                aria-describedby="modal-to-confirm-tag-deletion"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center',
                        borderRadius: 2,
                    }}
                >
                    <Typography id="delete-confirmation-modal" variant="h6" component="h2">
                        Are you sure you want to delete this Tag?
                    </Typography>
                    <Box mt={2} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDelete(tagData?.id)}
                            sx={{ mx: 1 }}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDeleteModalClose}
                            sx={{ mx: 1 }}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default TagList;
