import React, { useEffect, useState } from 'react';
import Header from '../header/Header';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import sizeConfigs from '../../config/sizeConfigs';
import colorConfigs from '../../config/colorConfigs';
import {
  Box,
  Toolbar,
  IconButton,
  Drawer,
  useMediaQuery,
  CssBaseline,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const location = useLocation(); 
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  useEffect(() => {
    if (isMobile) {
      setMobileOpen(false);
    }
  }, [location, isMobile]);

  const drawer = (
    <Box
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
      }}
    >
      <Sidebar />
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              ml: 2,
              mt: 1,
              position: 'fixed',
              zIndex: 1200,
              color: 'white', 
            }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }} 
            sx={{
              '& .MuiDrawer-paper': {
                width: sizeConfigs.sidebar.width,
              },
            }}
          >
            {drawer}
          </Drawer>
        </>
      ) : (
        <Box
          component="nav"
          sx={{
            width: sizeConfigs.sidebar.width,
            flexShrink: 0,
          }}
        >
          {drawer}
        </Box>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: isMobile
            ? '100%'
            : `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: '100vh',
          backgroundColor: colorConfigs.mainBg,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
