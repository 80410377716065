import { createSlice } from '@reduxjs/toolkit';
import { fetchIncentiveSteps, submitIncentive, finalIncentiveSubmit, fetchUncheckedIncentive, fetchCheckedIncentive } from '../actions/incentive.action.js';

const initialState = {
    checkedIncentiveList: [],
    uncheckedIncentive: [],
    incentiveSteps: [],
    ischeckedLoading: false,
    isUncheckedLoading: false,
    checkedError: null,
    uncheckedError: null,
};

const incentiveSlice = createSlice({
    name: 'incentive',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCheckedIncentive.pending, (state) => {
            state.ischeckedLoading = true;
        });
        builder.addCase(fetchCheckedIncentive.fulfilled, (state, action) => {
            state.ischeckedLoading = false;
            state.checkedIncentiveList = action.payload;
        });
        builder.addCase(fetchCheckedIncentive.rejected, (state, action) => {
            state.ischeckedLoading = false;
            state.checkedError = action.error.message;
        });

        builder.addCase(fetchUncheckedIncentive.pending, (state) => {
            state.isUncheckedLoading = true;
        });
        builder.addCase(fetchUncheckedIncentive.fulfilled, (state, action) => {
            state.isUncheckedLoading = false;
            state.uncheckedIncentive = action.payload;
        });
        builder.addCase(fetchUncheckedIncentive.rejected, (state, action) => {
            state.isUncheckedLoading = false;
            state.uncheckedError = action.error.message;
        });

        builder.addCase(fetchIncentiveSteps.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIncentiveSteps.fulfilled, (state, action) => {
            state.isLoading = false;
            state.incentiveSteps = action.payload;
        });
        builder.addCase(fetchIncentiveSteps.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });

        builder.addCase(submitIncentive.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(submitIncentive.fulfilled, (state, action) => { // eslint-disable-line no-unused-vars
            state.isLoading = false;
        });
        builder.addCase(submitIncentive.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });

        builder.addCase(finalIncentiveSubmit.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(finalIncentiveSubmit.fulfilled, (state, action) => { // eslint-disable-line no-unused-vars
            state.isLoading = false;
        });
        builder.addCase(finalIncentiveSubmit.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });
    },
});

export default incentiveSlice.reducer;
