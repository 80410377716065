import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Paper, Divider, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TherapistAvailability from '../../components/common/Availability/TherapistAvailabilty';
import { getToken } from '../../components/common/userLocalStorageUtils';

const cityMap = {
    1: { name: 'Pune', color: '#FF5733' },       // Red-Orange
    2: { name: 'Gurgaon', color: '#FFB300' },    // Amber
    3: { name: 'Chandigarh', color: '#FF6F61' }, // Coral
    4: { name: 'Kotla', color: '#4CAF50' },      // Green
    5: { name: 'Bangalore', color: '#1976D2' },  // Blue
    6: { name: 'Mumbai', color: '#E91E63' },     // Pink
    7: { name: 'Noida', color: '#9C27B0' },      // Purple
    8: { name: 'Ludhiana', color: '#FFC107' },   // Golden Yellow
    9: { name: 'Hyderabad', color: '#F44336' },  // Red
    10: { name: 'Pitampura', color: '#3F51B5' }, // Indigo
    11: { name: 'Ahemdabad', color: '#009688' }, // Teal
    12: { name: 'Indore', color: '#795548' },    // Brown
    13: { name: 'Surat', color: '#795548' },
};

const TherapistSelectionPage = () => {
    const [therapists, setTherapists] = useState([]);
    const [selectedTherapist, setSelectedTherapist] = useState(() => {
        const savedTherapistId = localStorage.getItem('selectedTherapistId');
        return savedTherapistId ? JSON.parse(savedTherapistId) : null;
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/partner/all-therapists`, {
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                token: getToken(),
            }
        })
            .then(res => setTherapists(res.data.therapists))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleTherapistChange = (event, value) => {
        setSelectedTherapist(value); // Set the full therapist object
        localStorage.setItem('selectedTherapistId', JSON.stringify(value)); // Save the therapist object to localStorage
    };

    return (
        <Paper elevation={0} style={{ padding: '30px', marginTop: '30px', backgroundColor: '#e3f2fd', border: 'none' }}>
            <Typography variant="h4" align="center" gutterBottom style={{ color: '#0077b6', fontWeight: 'bold', marginBottom: '20px' }}>
                Therapist Selection
            </Typography>
            <Divider style={{ margin: '20px 0', backgroundColor: '#0077b6' }} />
            <Box>
                <Typography variant="h6" gutterBottom style={{ color: '#023047', fontWeight: 'bold', marginBottom: '10px' }}>
                    Select Therapist
                </Typography>
                <Autocomplete
                    options={therapists}
                    getOptionLabel={(option) => {
                        const city = cityMap[option.center_id]?.name || 'Unknown City';
                        return `${option.name} - ${option.id} (${city})`;
                    }}
                    onChange={handleTherapistChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            value={selectedTherapist ? `${selectedTherapist.name} - ${selectedTherapist.id} (${cityMap[selectedTherapist.center_id]?.name})` : ''}
                            label="Select Therapist"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    renderOption={(props, option) => {
                        const city = cityMap[option.center_id];
                        return (
                            <Box {...props} display="flex" justifyContent="flex-start" width="100%" padding={1}>
                                <Typography style={{ marginRight: '8px' }}>
                                    {`${option.name} - ${option.id}`}
                                </Typography>
                                {city && (
                                    <Typography
                                        style={{
                                            backgroundColor: '#e3f2fd',
                                            borderRadius: '5px',
                                            padding: '2px 4px',
                                            color: city.color,
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {city.name}
                                    </Typography>
                                )}
                                <Typography style={{ marginLeft: '8px', fontSize: '0.8rem' }}>
                                    {option.gender}
                                </Typography>
                            </Box>
                        );
                    }}
                    value={selectedTherapist} // The full therapist object
                />
                {selectedTherapist && (
                    <TherapistAvailability partnerId={selectedTherapist.id} />
                )}
            </Box>
        </Paper>
    );
};

export default TherapistSelectionPage;
