import React, { useEffect, useState } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Button, MenuItem, Select, FormControl } from '@mui/material';
import axios from 'axios';
import { getToken } from '../userLocalStorageUtils';
import { hasAdminAndSuperAdminAccess } from '../UserRolesConfig';

const UserDetailsComponent = ({ data, machineDetails, handleSetOfficeLeftTime }) => {
    const [selection, setSelection] = useState('No');
    const [defaultSelection, setDefaultSelection] = useState('No');
    const [showButton, setShowButton] = useState(false);
    const [escalationData, setEscalationData] = useState(null);
    const role = JSON.parse(localStorage.getItem('userData'))?.user?.role; 

    const fetchEscalationDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/booking/escalation-remark/${data['User ID']}`, {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                    token: getToken(),
                },
            });
    
            if (response?.status === 400 && response?.data?.status?.message === 'Failed to fetch ScalatedClientRemarks') {
                setSelection('No');
                setDefaultSelection('No');
                setEscalationData({ is_escalated: 0, remarks: '' });
            } else if (response?.status === 200 && response?.data?.data) {
                const isEscalated = response?.data?.data?.is_escalated;
                const escalatedValue = isEscalated === 1 ? 'Yes' : 'No';
                setSelection(escalatedValue);
                setDefaultSelection(escalatedValue);
                setEscalationData(response.data.data);
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (error) {
            console.error('Error fetching escalation details:', error);
            setSelection('No');
            setDefaultSelection('No');
            setEscalationData({ is_escalated: 0, remarks: '' });
        }
    };    

    useEffect(() => {
        fetchEscalationDetails();
    }, [data['User ID']]);

    const handleSubmit = async () => {
        try {
            const adminData = JSON.parse(localStorage.getItem('userData'));
            const adminId = adminData?.user?.id;
            const adminName = adminData?.user?.name;
    
            const isEscalated = selection === 'Yes';
    
            const body = {
                adminId: adminId,                  
                userId: data['User ID'],           
                remarks: escalationData?.remarks, 
                adminName: adminName,               
                isEscalated: isEscalated
            };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/booking/add-update-scalation-remark`, body, {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                    token: getToken(),
                },
            });
    
            if (response.status === 200) {
                alert('Submitted successfully!');
                setShowButton(false);
                fetchEscalationDetails();
            } else {
                console.error('Error submitting the escalation:', response);
            }
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };

    const handleChange = (event) => {
        setSelection(event.target.value);
    };

    useEffect(() => {
        if (selection !== defaultSelection) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    }, [selection, defaultSelection]);

    const keys = Object.keys(data).filter(key => key !== 'User ID');
    const keys1 = Object.keys(machineDetails);
    const middleIndex = Math.ceil(keys.length / 2);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                    <TableContainer style={{ overflowX: 'auto' }}>
                        <h3>Client Details</h3>
                        <Table>
                            <TableBody>
                                {keys.slice(0, middleIndex + 1).map((key) => (
                                    <TableRow key={key}>
                                        <TableCell>
                                            <strong>{key}:</strong>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                key === 'Left for appointment' && data[key] ? data[key] : key === 'Left for appointment' && !data[key] ? <Button variant="contained" color="primary" type="submit" sx={{ height: '30px' }} onClick={handleSetOfficeLeftTime}>Left Time</Button> : key !== 'Escalated' && data[key]
                                            }
                                            {
                                                key === 'Escalated' && (
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <FormControl>
                                                            <Select
                                                                id="dropdown"
                                                                value={selection}
                                                                sx={selection === 'Yes' ? { color: 'red', height: '30px' } : { color: 'green', height: '30px' }}
                                                                onChange={handleChange}
                                                                disabled={!hasAdminAndSuperAdminAccess(role)}
                                                            >
                                                                <MenuItem value="Yes" sx={{ color: 'red' }}>Yes</MenuItem>
                                                                <MenuItem value="No" sx={{ color: 'green' }}>No</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                        {showButton && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                sx={{ height: '30px' }}
                                                                onClick={handleSubmit}
                                                            >
                                                                Submit
                                                            </Button>
                                                        )}
                                                    </div>
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                    <TableContainer style={{ overflowX: 'auto' }}>
                        <h3>Therapist Details</h3>
                        <Table>
                            <TableBody>
                                {keys.slice(middleIndex + 1).map((key) => (
                                    <TableRow key={key}>
                                        <TableCell>
                                            <strong>{key}:</strong>
                                        </TableCell>
                                        <TableCell>{data[key]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <h3>Machine Details</h3>
                        <Table>
                            <TableBody>
                                {keys1.map((key) => (
                                    <TableRow key={key}>
                                        <TableCell>
                                            <strong>{key}:</strong>
                                        </TableCell>
                                        <TableCell>{machineDetails[key]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default UserDetailsComponent;