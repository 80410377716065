import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCenter, addCenter, fetchAdmin, fetchCity } from '../../store/actions/center.action';
import { Grid, Button, Paper, Typography, Box, IconButton } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FormFields from './FormComponents';
import './AddCenterForm.css';
import { hasAdminAndSuperAdminAccess } from '../../components/common/UserRolesConfig';

const AddEditCenterForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const cityList = useSelector((state) => state.center?.cityList?.cities);
    const adminList = useSelector((state) => state.center?.adminList);
    const data = location?.state?.data?.data;
    const id = data?.Id;
    const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;

    useEffect(() => {
        dispatch(fetchCity());
        dispatch(fetchAdmin());
    }, [dispatch]);

    const [formData, setFormData] = useState({
        name: '',
        cityId: '',
        phone: '',
        timings: { startTime: '', endTime: '' },
        adminUserId: '',
        adminName: '',
        adminPhone: '',
        location: '',
        lat: '',
        long: '',
    });

    const [originalData, setOriginalData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);

    useEffect(() => {
        if (data) {
            const newData = {
                name: data?.['Center Name'],
                cityId: data?.['City Id'],
                phone: data?.['Center Phone'],
                timings: {
                    startTime: data?.['Start Time'],
                    endTime: data?.['End Time'],
                },
                adminUserId: data?.['Admin User Id'],
                adminName: data?.['Admin Name'],
                adminPhone: data?.['Admin Phone'],
                location: data?.['Address'],
                lat: data?.['Lat'],
                long: data?.['Long'],
            };
            setFormData(newData);
            setOriginalData(newData); // Save the original data to reset later
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData };

        if (name === 'adminUserId') {
            const selectedAdmin = adminList.find((admin) => admin.id === parseInt(value));
            updatedFormData = {
                ...updatedFormData,
                adminUserId: value,
                adminName: selectedAdmin?.name || '',
                adminPhone: selectedAdmin?.phone || '',
            };
        } else if (name === 'startTime' || name === 'endTime') {
            updatedFormData = {
                ...updatedFormData,
                timings: {
                    ...updatedFormData.timings,
                    [name]: value,
                },
            };
        } else {
            updatedFormData = {
                ...updatedFormData,
                [name]: value,
            };
        }

        setFormData(updatedFormData);
        setShowSubmit(JSON.stringify(updatedFormData) !== JSON.stringify(originalData));
    };

    const handleReset = () => {
        setFormData(originalData); // Reset the form to the original values
        setShowSubmit(false); // Hide the submit button after reset
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const res = data ? await UpdateCenter(id, formData) : await addCenter(formData);
            if (res?.status === 200) {
                alert(res?.data?.status?.message);
                navigate('/centerlist');
            } else if (res?.response?.data?.status?.code === 400) {
                alert(res?.response?.data?.status?.message);
            } else {
                alert('Unhandled response:', res);
            }
        } catch (error) {
            alert('An error occurred. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Paper elevation={3} sx={{ padding: 4, margin: 'auto', maxWidth: 800, marginTop: 2, borderRadius: 2 }}>
            <Box sx={{position: 'relative'}}>
                <IconButton color='primary' onClick={() => navigate('/centerlist')} sx={{position: 'absolute', top: 0, left: 0}}><KeyboardBackspaceIcon /></IconButton>
                <Typography variant="h4" align="center" gutterBottom sx={{ color: '#0077b6', fontWeight: 'bold' }}>
                    {data ? 'Update Center' : 'Add Center'}
                </Typography>
                {/* Reset Button (Only show when in edit mode and data is present) */}
                {data && showSubmit && (
                            <Typography
                                // variant="outlined"
                                color="secondary"
                                onClick={handleReset}
                                sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px', cursor: 'pointer', position: 'absolute', top: 0, right: 0, fontWeight: 300, fontSize: 15 }}
                            >
                                RESET
                            </Typography>
                )}
            </Box>
            <Box component="form" marginTop={5} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <FormFields formData={formData} handleChange={handleChange} cityList={cityList} adminList={adminList} />

                    {/* Submit Button */}
                    { hasAdminAndSuperAdminAccess(role) && (
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting || !showSubmit}
                                sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px' }}
                            >
                                {isSubmitting ? (data ? 'Updating...' : 'Submitting...') : (data ? 'Update' : 'Submit')}
                            </Button>
                        </Grid>
                    )}

                </Grid>
            </Box>
        </Paper>
    );
};

export default AddEditCenterForm;



// import React, { useEffect, useState } from 'react';
// import './AddCenterForm.css';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { UpdateCenter, addCenter, fetchAdmin, fetchCity } from '../../store/actions/center.action';
// import { useDispatch, useSelector } from 'react-redux';

// const AddEditCenterForm = () => {
//     const navigate = useNavigate()
//     const location = useLocation();
//     const dispatch = useDispatch()
//     const cityList = useSelector(state => state.center?.cityList?.cities)
//     const  adminList = useSelector(state => state.center?.adminList)
//     const data = location?.state?.data?.data;
//     const id = data?.Id;
//     useEffect(() => {
//         dispatch(fetchCity())
//         dispatch(fetchAdmin())
//     }, [dispatch])
//     const [formData, setFormData] = useState({
//         name: '',
//         cityId: '',
//         phone: '',
//         timings: {
//             startTime: '',
//             endTime: ''
//         },
//         adminUserId: '',
//         adminName: '',
//         adminPhone: '',
//         location:'',
//         lat:'',
//         long:''
//     });
//     useEffect(() => {
//         if (data) {
//             setFormData(prevData => ({
//                 ...prevData,
//                 name: data?.['center Name'],
//                 phone: data?.['Center Phone'],
//                 timings: {
//                     startTime: data?.['Start Time'],
//                     endTime: data?.['End Time']
//                 },
//                 adminUserId: data?.['Admin User Id'],
//                 adminName: data?.['Admin Name'],
//                 adminPhone: data?.['Admin Phone'],
//                 location:data?.["Address"],
//                 lat:data?.["lat"],
//                 long:data?.["long"],
//                 cityId:data?.["City Id"]
//             }));
//         }
//     }, [data]);


//     const [isSubmitting, setIsSubmitting] = useState(false);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         if (name === "adminUserId") {
//             const selectedAdmin = adminList.find(admin => admin.id === parseInt(value));
//             if (selectedAdmin) {
//                 setFormData(prevData => ({
//                     ...prevData,
//                     adminUserId: value,
//                     adminName: selectedAdmin.name,
//                     adminPhone: selectedAdmin.phone
//                 }));
//             } else {
//                 setFormData(prevData => ({
//                     ...prevData,
//                     adminUserId: value,
//                     adminName: '',
//                     adminPhone: ''
//                 }));
//             }
//         } else if (name === 'startTime' || name === 'endTime') {
//             setFormData(prevData => ({
//                 ...prevData,
//                 timings: {
//                     ...prevData.timings,
//                     [name]: value
//                 }
//             }));
//         } else {
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [name]: value
//             }));
//         }
//     };
    

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsSubmitting(true);
//         try {
//             const res = data ? await UpdateCenter(id, formData) : await addCenter(formData);
//             if (res?.status === 200) {
//                 alert(res?.data?.status?.message);
//                 navigate("/centerlist");
//             } else if (res?.response?.data?.status?.code === 400) {
//                 alert(res?.response?.data?.status?.message);
//             } else {
//                 alert("Unhandled response:", res);
//             }
//         } catch (error) {
//             alert("An error occurred. Please try again later.");
//         } finally {
//             setIsSubmitting(false);
//         }
//     };




//     const [startTime, setStartTime] = useState('');
//     const [endTime, setEndTime] = useState('');

//     const handleChangeStartTime = (event) => {
//         const newStartTime = event.target.value;
//         setStartTime(newStartTime);
//         if (newStartTime > endTime) {
//             setEndTime(newStartTime);
//         }
//     };

//     const handleChangeEndTime = (event) => {
//         const newEndTime = event.target.value;
//         setEndTime(newEndTime);
//         if (newEndTime < startTime) {
//             setStartTime(newEndTime);
//         }
//     };

//     return (
//         <div className="add-center-form">
//             <h3>{data ? "Update Center" : "Add Center"}</h3>
//             <form onSubmit={handleSubmit}>
//                 <div className="form-row">
//                     <div className="form-group">
//                         <label htmlFor="name">Center Name</label>
//                         <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="phone">Center Phone</label>
//                         <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange}
//                             pattern="[0-9]{10}"
//                             title="Please enter a 10-digit phone number"
//                             required />
//                     </div>
//                 </div>
//                 <div className="form-row">
//                     <div className="form-group">
//                         <label htmlFor="location">Center Address</label>
//                         <input type="text" id="location" name="location" value={formData.location} onChange={handleChange} required />
//                     </div>
                    
//                 </div>
//                 <div className="form-row">
//                     <div className="form-group">
//                         <label htmlFor="lat">Center Lat</label>
//                         <input type="text" id="lat" name="lat" value={formData.lat} onChange={handleChange} required />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="long">Center Long</label>
//                         <input  name="long" value={formData.long} onChange={handleChange}
//                             required />
//                     </div>
//                 </div>

//                 <div className="form-row">
//                     <div className="form-group">
//                         <label htmlFor="startTime">Center Start Time</label>
//                         <input type="time" step="3600" id="startTime" name="startTime" value={formData.timings.startTime} onChange={handleChange} required />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="endTime">Center End Time</label>
//                         <input type="time" step="3600" id="endTime" name="endTime" value={formData.timings.endTime} onChange={handleChange}

//                             required />
//                     </div>
//                 </div>
//                 <div className="form-row">
//                     <div className="form-group">
//                         <label htmlFor="cityId">City</label>
//                         <select id="cityId" name="cityId" value={formData.cityId} onChange={handleChange} required>
//                             <option value="">Select City</option>
//                             {cityList?.map(city => (
//                                 <option key={city.id} value={city.id}>{city.name}</option>
//                             ))}
//                         </select>
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="adminUserId">Center Admin</label>
//                         <select id="adminUserId" name="adminUserId" value={formData.adminUserId} onChange={handleChange} required>
//                             <option value="">Select Admin</option>
//                             {adminList?.map(admin => (
//                                 <option key={admin.id} value={admin.id}>{admin.name}</option>
//                             ))}
//                         </select>
//                     </div>

//                 </div>
//                 <div className="form-row">
//                     <div className="form-group">
//                         <label htmlFor="adminName">Admin Name</label>
//                         <input type="text" id="adminName" name="adminName" value={formData.adminName} onChange={handleChange} disabled />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="adminPhone">Admin Phone</label>
//                         <input type="tel" id="adminPhone" name="adminPhone" value={formData.adminPhone} onChange={handleChange}
//                             pattern="[0-9]{10}"
//                             title="Please enter a 10-digit phone number"
//                             disabled />
//                     </div>
//                 </div>
//                 <button
//                     className="add-edit-button"
//                     type="submit"
//                     disabled={isSubmitting}
//                     style={{
//                         background: isSubmitting ? 'gray' : '#007bff',
//                         cursor: isSubmitting ? 'not-allowed' : 'pointer'
//                     }}
//                 >
//                     {isSubmitting ? (data ? 'Updating...' : 'Submitting...') : (data ? 'Update' : 'Submit')}
//                 </button>
//             </form>
//         </div>
//     );
// }

// export default AddEditCenterForm;
