import React, { useEffect, useState } from 'react';
import './ProductDetails.style.css';
import TableComponent from '../../components/common/TableComponent/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProduct } from '../../store/actions/product.action';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import AddDeleteTagModal from '../Tags/AddDeleteTagModal';

const ProductDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const productList = useSelector((state) => state.product.productList);

  const handleTagModal = (row) => {
    setSelectedProduct(row);
    setModalOpen(true);
};

const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
};
  const handleUpdateDetails = (id) => {
    console.log(`Product with ID ${id} updated`);
  };
  const handleViewDetails = (details) => {
    navigate('/viewproductdetails', { state: { details } });
  };

  const stringifiedUser = localStorage.getItem("userData")
  const userData = stringifiedUser? JSON.parse(stringifiedUser):null
  const concentrixUser = userData && userData.user && userData.user.concentrixUser? userData.user.concentrixUser:false 

  useEffect(() => {
    dispatch(fetchProduct());
  }, [dispatch]);

  return (
    <div>
      <h3>Product Details</h3>
      <>
        {productList?.length > 0 ? (
          <TableComponent
            data={productList}
            hiddenFields={['image', 'description', 'slug']}
            viewButton={'view'}
            updateDetails={handleUpdateDetails}
            viewDetails={handleViewDetails}
            openModalButton={"Add Tag"}
            handleTagButton={handleTagModal}
          />
        ) : (
          <LoaderComponent />
        )}
        {selectedProduct && (
          <AddDeleteTagModal
            open={isModalOpen}
            onClose={handleCloseModal}
            itemData={selectedProduct}
            type={"product"}
          />
        )}
      </>
    </div>
  );
};

export default ProductDetails;
