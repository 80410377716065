import { createSlice } from "@reduxjs/toolkit";
import { getItemTag, getTag } from "../actions/tag.action";
const initialState = {
    tagList: [],
    itemTagList: [],
    tagListLoading: false,
    error: null,
};

const tagSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getTag.pending, (state) => {
            state.tagListLoading = true;
        });
        builder.addCase(getTag.fulfilled, (state, action) => {
            state.tagListLoading = false;
            state.tagList = action.payload;
        });
        builder.addCase(getTag.rejected, (state, action) => {
            state.tagListLoading = false;
            state.error = action.error.message;
        });

        builder.addCase(getItemTag.pending, (state) => {
            state.tagListLoading = true;
        });
        builder.addCase(getItemTag.fulfilled, (state, action) => {
            state.tagListLoading = false;
            state.itemTagList = action.payload;
        });
        builder.addCase(getItemTag.rejected, (state, action) => {
            state.tagListLoading = false;
            state.error = action.error.message;
        });
    },
});

export default tagSlice.reducer;
