import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import useDialogLogic from './DialogLogic';
import { fetchTherapistServiceAbleProducts } from '../../../store/actions/therapist.action';
import LoaderComponent from '../LoaderComponent/LoaderComponent';
import { fetchMachineServiceAbleProducts } from '../../../store/actions/machine.action';
import { hasAdminAndSuperAdminAccess } from '../UserRolesConfig';
import { hasSuperAccess } from '../../../utils/access';

const DialogComponent = ({ open, onClose, selectedRow, label }) => {
  
  // Using the custom hook for logic
  const {
    searchTerm,
    filteredProducts,
    loading,
    productsToDelete,
    productsToAdd,
    handleDeleteProduct,
    handleAddProduct,
    setSearchTerm,
    handleSubmit,
  } = useDialogLogic(open, onClose, selectedRow.entityId, label);

  const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
  const id = JSON.parse(localStorage.getItem('userData'))?.user?.id;
  const dispatch = useDispatch();

  // Fixing entityProducts selector logic
  const entityProducts = useSelector(state => 
    label === "Therapist" 
      ? state?.therapist?.therapistServiceableProducts?.products || [] 
      : label === "Machine" 
      ? state?.machine?.machineServiceableProducts?.products || [] 
      : [] // Default to empty array if label doesn't match
  );

  const loadingTh = useSelector(state => 
    label === "Therapist"
    ? state?.therapist?.isLoadingtherapistServiceableProducts
    : label === "Machine" 
    ? state?.machine?.isLoadingMachineServiceableProducts 
    : false // Default to false if label doesn't match
  );

  // Fetch products based on the label
  useEffect(() => {
    if (open && selectedRow) {
      if (label === "Therapist") {
        dispatch(fetchTherapistServiceAbleProducts(selectedRow.entityId));
      } else if (label === "Machine") {
        dispatch(fetchMachineServiceAbleProducts(selectedRow.entityId));
      }
    }
  }, [dispatch, open, selectedRow, label]);

  // Calculate height based on the number of products
  const calculateBoxHeight = () => {
    if (filteredProducts.length === 0) return '30px';
    const maxVisibleItems = 35;
    const itemHeight = 35;
    const boxHeight = Math.min(filteredProducts.length, maxVisibleItems) * itemHeight;
    return `${boxHeight}px`;
  };

  // Get products to display based on search term
  const getProductsToDisplay = () => {
    if (searchTerm) {
      return filteredProducts.filter(product => 
        product.serviceName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return entityProducts; // Show only entity's products when no search term
  };

  // Filter products to avoid duplicates
  const remainingProducts = getProductsToDisplay().filter(product => 
    !productsToAdd.some(p => p.productId === product.productId) &&
    !productsToDelete.some(p => p === product.productId)
  );

  return (
    <>
      {loading || loadingTh ? <LoaderComponent /> : 
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="md" 
        fullWidth 
        sx={{ 
          '& .MuiDialog-paper': { 
            maxHeight: 'calc(100vh - 40px)', 
            height: 'fit-content', 
            overflowY: 'auto', 
            backdropFilter: 'blur(8px)', 
            backgroundColor: 'rgba(255, 255, 255)', 
          }
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 600, fontSize: 25 }}>
          {label} Products
          <IconButton
            onClick={() => {onClose(); setSearchTerm('')}}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedRow ? (
            <Box>
              <Typography variant="body1">
                <span style={{ fontWeight: 600, marginBottom: '5px' }}>{label} Name:</span> {selectedRow.entityName.toUpperCase()}
              </Typography>
              <Typography variant="body1">
                <span style={{ fontWeight: 600, marginBottom: '5px' }}>Center Name:</span> {selectedRow.centerName}
              </Typography>
              <Typography variant="body1">
                <span style={{ fontWeight: 600, marginBottom: '5px' }}>Center Phone:</span> {selectedRow.centerPhone}
              </Typography>

              {/* Search Input for Products */}
              <TextField
                fullWidth
                variant="outlined"
                label="Search Products"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ marginTop: '20px', marginBottom: '10px' }}
              />

              {/* Displaying products initially and filtered based on search term */}
              <Box 
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxHeight: calculateBoxHeight(), 
                  minHeight: '60px', 
                  overflowY: 'auto', 
                  padding: '5px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  bgcolor: 'rgba(0, 0, 255, 0.1)', 
                }}
              >
                {/* Display productsToAdd at the top when no search term */}
                { productsToAdd.length > 0 && (
                  productsToAdd.map((product) => (
                    <Box
                      key={product.productId}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: '4px',
                        padding: '5px',
                        margin: '5px',
                      }}
                    >
                      <Typography variant="body2" sx={{ marginRight: '5px' }}>
                        {product.serviceName} ({product.serviceGender})
                      </Typography>
                      <IconButton 
                        onClick={() => handleAddProduct(product)} 
                        sx={{ color: '#4CAF50', padding: '2px' }}
                      >
                        <CheckCircleIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))
                )}

                {/* Display products marked for deletion */}
                { productsToDelete.length > 0 && (
                  productsToDelete.map((productId) => {
                    const product = entityProducts.find(p => p.productId === productId);
                    if (!product) return null; // Skip if the product is not found
                    
                    return (
                      <Box
                        key={product.productId}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          borderRadius: '4px',
                          padding: '5px',
                          margin: '5px',
                        }}
                      >
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            marginRight: '5px', 
                            textDecoration: 'line-through',
                          }}
                        >
                          {product.serviceName} ({product.serviceGender})
                        </Typography>
                        <IconButton 
                          onClick={() => handleDeleteProduct(product.productId)} 
                          sx={{ color: 'red', padding: '2px' }}
                        >
                          <CancelIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    );
                  })
                )}

                {/* Display remaining products */}
                {remainingProducts.length > 0 ? (
                  remainingProducts.map((product) => {
                    if (!product.serviceName) return null; // Return null if serviceName doesn't exist
                    
                    return (
                      <Box
                        key={product.productId}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          borderRadius: '4px',
                          padding: '5px',
                          margin: '5px',
                        }}
                      >
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            marginRight: '5px', 
                            textDecoration: productsToDelete.some(p => p === product.productId) ? 'line-through' : '',
                          }}
                        >
                          {product.serviceName} ({product.serviceGender})
                        </Typography>
                        
                        {/* Display delete or add based on if product exists */}
                        {entityProducts.some(p => p.productId === product.productId) ? (
                          <IconButton 
                            onClick={() => handleDeleteProduct(product.productId)} 
                            sx={{ color: productsToDelete.some(p => p === product.productId) ? 'red' : 'rgb(0, 0, 255, 0.5)', padding: '2px' }}
                          >
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        ) : (
                          <IconButton 
                            onClick={() => handleAddProduct(product)} 
                            sx={{ color: productsToAdd.some(p => p.productId === product.productId) ? 'green' : 'orange', padding: '2px' }}
                          >
                            {productsToAdd.some(p => p.productId === product.productId) ? (
                              <CheckCircleIcon fontSize="small" />
                            ) : (
                              <AddCircleIcon fontSize="small" sx={{ color: 'darkgray' }} />
                            )}
                          </IconButton>
                        )}
                      </Box>
                    );
                  })                  
                ) : (
                  <Typography variant="body2">No services available.</Typography>
                )}
              </Box>
            </Box>
          ) : (
            <Typography variant="body2" sx={{ textAlign: 'center'}}>No Details Available</Typography>
          )}
        </DialogContent>
        {hasSuperAccess(id) && <DialogActions sx={{ padding: '5px', display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>}
      </Dialog>}
    </>
  );
};

export default DialogComponent;