import React, { useEffect } from 'react';
import { Box, Button, Fab, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCenter } from '../../store/actions/center.action';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import CenterCard from './CenterCard';
import { hasSuperAccess } from '../../utils/access';

const CenterListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let centerList = useSelector((state) => state.center?.centerList?.centers);
  let isLoadingCenterList = useSelector((state) => state.center?.isLoadingCenterList);
  const id = JSON.parse(localStorage.getItem('userData'))?.user?.id;

  // Sort centerList by Center ID
  centerList = centerList
    ?.map((data) => ({
      Id: data?.id,
      'Center Name': data?.name,
      'Center Phone': data?.phone,
      'Admin Name': data?.adminName,
      'Admin Phone': data?.adminPhone,
      'Admin User Id': data?.adminUserId,
      'City Id': data?.cityId,
      'Start Time': data?.timings?.startTime,
      'End Time': data?.timings?.endTime,
      Address: data?.location,
      Lat: data?.lat,
      Long: data?.long,
    }))
    .sort((a, b) => a.Id - b.Id) || []; // Sort by center Id

  const handleEdit = (data) => {
    if (data) {
      navigate('/addedit-center', {
        state: { data: { data } },
      });
    }
  };

  useEffect(() => {
    dispatch(fetchCenter());
  }, [dispatch]);

  return (
    <Box>
      {hasSuperAccess(id) && <Box sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
        <Fab color="primary" variant="extended" onClick = {() => navigate('/addedit-center')}>
          <AddIcon sx={{ mr: 1 }} />
          Add Center
        </Fab>
      </Box>}
      <Grid container spacing={3}>
        {isLoadingCenterList ? (
          <LoaderComponent />
        ) : (centerList.length > 0 &&
          centerList.map((center, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <CenterCard center={center} handleEdit={handleEdit} access = {hasSuperAccess(id)}/>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default CenterListing;

// import React, { useEffect } from 'react'
// import TableComponent from '../../components/common/TableComponent/TableComponent'
// import { Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import EditIcon from '@mui/icons-material/Edit';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchCenter } from '../../store/actions/center.action';
// import { hasAdminAndSuperAdminAccess } from '../../components/common/UserRolesConfig';
// import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';

// const CenterListing = () => {
//   const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
//   const navigate = useNavigate()
//   const dispatch = useDispatch()
//   let centerList = useSelector(state => state.center?.centerList?.centers)
//   let isLoadingCenterList = useSelector(state => state.center?.isLoadingCenterList)
//   centerList = centerList?.map((data) => {
//     return {
//       'Id':data?.id,
//       'center Name':data?.name ,
//       'Center Phone':data?.phone,
//       'Admin Name': data?.adminName,
//       "Admin Phone": data?.adminPhone,
//       'Admin User Id': data?.adminUserId,
//       'City Id': data.cityId,
//       'Start Time':data?.timings?.startTime,
//       'End Time':data?.timings?.endTime,
//        'Address':data?.location,
//        'lat':data?.lat,
//        'long':data?.long
//     };
//   });
  
//   const handleEdit = (data) => {
//     if (data) {
//       navigate("/addedit-center", {
//         state: { data: { data } },
//       });
//     }
//   };
//   useEffect(() => {
//     dispatch(fetchCenter())
//   }, [dispatch])

//   return (
//     <div>

//       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "30px" }}>
//         <h3 style={{ margin: '0 auto' }}>All Centers</h3>
//         {hasAdminAndSuperAdminAccess(role)&&<Button variant="contained" color="primary" onClick={() => navigate("/addedit-center")}>Add Center</Button>}
//       </div>
//       {isLoadingCenterList?<LoaderComponent/>:<TableComponent data={centerList}
//         hiddenFields={["timings"]}
//         viewButton={hasAdminAndSuperAdminAccess(role)&&<EditIcon />}
//         viewDetails={handleEdit}
//       />}
//     </div>
//   )
// }

// export default CenterListing